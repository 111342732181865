import { BASE_QUIZ, type BaseQuizRoute, SUBSCRIPTION as BASE_SUBSCRIPTION } from '@web-solutions/base-app/constants/routes';

import type { AfterPurchaseRoutes } from './screens';

export const LOCAL_QUIZ = {
  MAGIC_OLD: 'MAGIC_OLD',
  EMAIL_OLD: 'EMAIL_OLD',
  SPOTEN_LOCATE_MOBILE_PHONE: 'SPOTEN_LOCATE_MOBILE_PHONE',
  SPOTEN_LOCATE_MOBILE_PHONE_OLD: 'SPOTEN_LOCATE_MOBILE_PHONE_OLD',
  SPOTEN_FIND_WHO: 'SPOTEN_FIND_WHO',
  SPOTEN_FIND_WHO_OLD: 'SPOTEN_FIND_WHO_OLD',
  SPOTEN_FEEL_ANXIOUS: 'SPOTEN_FEEL_ANXIOUS',
  SPOTEN_FEEL_HIDING: 'SPOTEN_FEEL_HIDING',
  SPOTEN_SUSPECT_CHEATING: 'SPOTEN_SUSPECT_CHEATING',
  SPOTEN_MORE_DISTANT: 'SPOTEN_MORE_DISTANT',
  SPOTEN_MORE_TIME_AWAY: 'SPOTEN_MORE_TIME_AWAY',
  SPOTEN_MORE_SECRETIVE: 'SPOTEN_MORE_SECRETIVE',
  SPOTEN_KNOWING_WHERE: 'SPOTEN_KNOWING_WHERE',
  SPOTEN_GENDER: 'SPOTEN_GENDER',
  SPOTEN_WELCOME_MEMBERS: 'SPOTEN_WELCOME_MEMBERS',
  SPOTEN_WELCOME_PARTNER_LOCATE: 'SPOTEN_WELCOME_PARTNER_LOCATE',
  SPOTEN_WELCOME_LOCATE_MEMBERS: 'SPOTEN_WELCOME_LOCATE_MEMBERS',
  SPOTEN_CLOSE_RELATIONSHIPS: 'SPOTEN_CLOSE_RELATIONSHIPS',
  SPOTEN_BEHAVIOR_CHANGE: 'SPOTEN_BEHAVIOR_CHANGE',
  SPOTEN_MORE_THAN_USUAL: 'SPOTEN_MORE_THAN_USUAL',
  SPOTEN_DELETING_MESSAGES: 'SPOTEN_DELETING_MESSAGES',
  SPOTEN_ACTING_DIFFERENTLY: 'SPOTEN_ACTING_DIFFERENTLY',
  SPOTEN_QUESTIONING_FAITHFULNESS: 'SPOTEN_QUESTIONING_FAITHFULNESS',
  SPOTEN_IMAGINE: 'SPOTEN_IMAGINE',
  SPOTEN_TRANSITION_FEELING_STRAINED: 'SPOTEN_TRANSITION_FEELING_STRAINED',
  SPOTEN_TRANSITION_ANXIETY: 'SPOTEN_TRANSITION_ANXIETY',
  SPOTEN_TRANSITION_PEACE_OF_MIND: 'SPOTEN_TRANSITION_PEACE_OF_MIND',
  SPOTEN_TRANSITION_NO_MORE: 'SPOTEN_TRANSITION_NO_MORE',
  SPOTEN_TRANSITION_KNOW_LOCATION: 'SPOTEN_TRANSITION_KNOW_LOCATION',
  SPOTEN_TRANSITION_KNOW_LOCATION_OLD: 'SPOTEN_TRANSITION_KNOW_LOCATION_OLD',
  SPOTEN_WELCOME_LOCATION: 'SPOTEN_WELCOME_LOCATION',
  SPOTEN_WELCOME_ALERTS: 'SPOTEN_WELCOME_ALERTS',
  SPOTEN_REASON: 'SPOTEN_REASON',
  SPOTEN_PLACE: 'SPOTEN_PLACE',
  SPOTEN_PLACE_OLD: 'SPOTEN_PLACE_OLD',
  SPOTEN_SOS_ALERTS: 'SPOTEN_SOS_ALERTS',
  SPOTEN_LOCATION_HISTORY: 'SPOTEN_LOCATION_HISTORY',
  SPOTEN_FOLLOWING: 'SPOTEN_FOLLOWING',
  SPOTEN_FOLLOWING_OLD: 'SPOTEN_FOLLOWING_OLD',
  SPOTEN_HOW_MANY_PEOPLE: 'SPOTEN_HOW_MANY_PEOPLE',
  SPOTEN_FEATURES: 'SPOTEN_FEATURES',
  SPOTEN_LOVED_BEHAVIOR_CHANGE: 'SPOTEN_LOVED_BEHAVIOR_CHANGE',
  SPOTEN_LOVED_CHEATING: 'SPOTEN_LOVED_CHEATING',
  SPOTEN_DIFFERENT_DEVICES: 'SPOTEN_DIFFERENT_DEVICES',
  SPOTEN_LOVED_WORRIES_MOST: 'SPOTEN_LOVED_WORRIES_MOST',
  SPOTEN_MEMBER_BEHAVIOR_CHANGE: 'SPOTEN_MEMBER_BEHAVIOR_CHANGE',
  SPOTEN_MEMBER_WORRIES_MOST: 'SPOTEN_MEMBER_WORRIES_MOST',
  SPOTEN_MEMBER_KEEP_CONTACT: 'SPOTEN_MEMBER_KEEP_CONTACT',
  SPOTEN_CRASH_DETECTION: 'SPOTEN_CRASH_DETECTION',
  SPOTEN_CHECK_LOCATION: 'SPOTEN_CHECK_LOCATION',
  SPOTEN_PLACE_ALERTS: 'SPOTEN_PLACE_ALERTS',
} as const;

export const QUIZ = {
  ...BASE_QUIZ,
  ...LOCAL_QUIZ,
} as const;

type QuizNameKeys = keyof typeof LOCAL_QUIZ;
export type QuizRoute = (typeof LOCAL_QUIZ[QuizNameKeys]) | BaseQuizRoute;

export const SUBSCRIPTION = { ...BASE_SUBSCRIPTION, GPS_TRACKER_UPSALE: '/subscription/gps-tracker-upsale' }

export const SCREENS_WITHOUT_PROGRESSBAR = [
  SUBSCRIPTION.MAIN,
  SUBSCRIPTION.SUCCESS,
  BASE_QUIZ.EMAIL,
  BASE_QUIZ.MAGIC,
  QUIZ.SPOTEN_LOCATE_MOBILE_PHONE,
  QUIZ.SPOTEN_LOCATE_MOBILE_PHONE_OLD,
  QUIZ.EMAIL_OLD,
  QUIZ.MAGIC_OLD,
  QUIZ.SPOTEN_WELCOME_MEMBERS,
  QUIZ.SPOTEN_WELCOME_PARTNER_LOCATE,
  QUIZ.SPOTEN_WELCOME_LOCATE_MEMBERS,
  QUIZ.SPOTEN_DIFFERENT_DEVICES,
];

export const MULTI_VARIANCE_SCREENS: string[] = [
  QUIZ.SPOTEN_REASON,
  QUIZ.SPOTEN_PLACE,
  QUIZ.SPOTEN_PLACE_OLD,
]

export const ROUTES = null

export const AFTER_PURCHASE_ROUTES: AfterPurchaseRoutes = ['GPS_TRACKER_UPSALE']
