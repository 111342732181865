import type { FC, ComponentProps, ReactNode } from 'react'
import classNames from 'classnames'

import { Text } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface PriceProps extends ComponentProps<'div'> {
  activePrice: string;
  notActivePrice: string | ReactNode;
  discount: string
}

export const Price: FC<PriceProps> = ({ activePrice, notActivePrice, discount, className, ...props }) =>
  <div className={classNames(classes.wrapper, className)} {...props}>
    <Text className={classNames(classes.price, classes.activePrice)}>
      {activePrice}
    </Text>
    <Text className={classNames(classes.price, classes.notActivePrice)}>
      {notActivePrice}
      <span className={classNames(classes.price, classes.discount)}>
        {discount}
      </span>
    </Text>
  </div>

