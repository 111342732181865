import _merge from 'lodash/merge';

import Localization from '@web-solutions/module-localization';
import { initLocalization as initMS } from '@web-solutions/manage-subscription';

import base from '@web-solutions/base-app/localization/en.json';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

import en from './en.json';

const resources = {
  en: {
    translation: _merge({}, base, en),
  },
};

export function initLocalization() {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
  initMS();
};

