import {
  type Nullable,
} from '@web-solutions/core/constants/remote-config';
import { InterButtonConfig } from '@web-solutions/core/payment/constants';

import {
  INITIAL_CONFIG as BASE_INITIAL_CONFIG,
  type RemoteConfig as BaseRemoteConfig,
  SummarySection as BaseSummarySection,
} from '@web-solutions/base-app/constants/remote-config';
import type { BaseOfferProductTypes } from '@web-solutions/core/constants/remote-config'
import type { DefaultUpsaleOfferType } from '@web-solutions/core/interfaces/upsaleOffers';
import { CoreSummarySection } from '@web-solutions/core/summary/types';

import { type QuizRoute } from './routes';
import type { AfterPurchaseRoutes } from './screens';

enum LocalSummarySection {
  SPOTEN_HEADER = 'spoten_header',
  SPOTEN_FEATURES_YOU_GET = 'spoten_features_you_get',
  SPOTEN_MAP = 'spoten_map',
  SPOTEN_CHECK_LOCATION = 'spoten_check_location',
  SPOTEN_CHECK_LOCATION_OLD = 'spoten_check_location_old',
  SPOTEN_POSSIBILITIES = 'spoten_possibilities'
}

export const SummarySection = { ...LocalSummarySection, ...BaseSummarySection }
export type SummarySectionType = LocalSummarySection | BaseSummarySection;

export enum OfferProductId {
  GPS_TRACKER = 'gps_tracker',
}

export type OfferProductTypes = BaseOfferProductTypes<OfferProductId>

type UpsaleOffer = DefaultUpsaleOfferType<Omit<OfferProductTypes, 'id'>>

export type AfterPurchaseFlow = AfterPurchaseRoutes;

export interface RemoteConfig
  extends Omit<
    BaseRemoteConfig<QuizRoute>,
    'landingStack'
    | 'landingButtons'
    | 'emailPageSections'
    | 'magicType'
  > {
  landingStack: Array<SummarySectionType | CoreSummarySection>,
  landingButtons: Array<InterButtonConfig<SummarySectionType>>,
  emailPageSections: Array<SummarySectionType>,
  magicType: 'map_loader' | '',
  isRequiredPhone: boolean,
  afterPurchaseFlow: AfterPurchaseFlow,
  gpsTrackerUpsale: UpsaleOffer
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...BASE_INITIAL_CONFIG,
  landingStack: [],
  magicType: '',
  isRequiredPhone: false,
  flow: [],
  consentCookieModal: {
    enabled: false,
    type: 'long',
    placement: 'SPOTEN_FIND_WHO',
  },
  afterPurchaseFlow: ['GPS_TRACKER_UPSALE'],
  gpsTrackerUpsale: {
    title: '',
    text: '',
    button: '',
    product: {
      title: '1 GPS unit',
      subTitle: '',
      notActivePrice: '$49.99',
      activePrice: '$29.99',
      discount: '60%',
      price: 1,
      currency: 'USD',
    },
    successModalEnabled: true,
    autoRedirectEnabled: false,
    view: "WITH_IMAGE",
  },
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;
