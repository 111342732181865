//@ts-ignore
import type { ProfileState } from '@web-solutions/base-app/store/profile';

import request from '@web-solutions/core/utils/network';

import type { State as SummaryState } from 'src/store/summary'

type UpdateProfileData = Partial<ProfileState & SummaryState>

export const updateProfile = async (data: UpdateProfileData) => request.post('/profile', {
  metadata: JSON.stringify({
    phone: data.phone,
  })
});