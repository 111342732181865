import { ImagesMap } from 'core/interfaces/images';

// welcome
import LOCATION_1X from './location/pic.webp';
import LOCATION_2X from './location/pic@2x.webp';
import LOCATION_3X from './location/pic@3x.webp';

import PLACE_1X from './place-alerts/pic.webp';
import PLACE_2X from './place-alerts/pic@2x.webp';
import PLACE_3X from './place-alerts/pic@3x.webp';

import CRASH_1X from './crash-detection/pic.webp';
import CRASH_2X from './crash-detection/pic@2x.webp';
import CRASH_3X from './crash-detection/pic@3x.webp';

import CHECK_LOCATION_1X from './check-location/pic.webp';
import CHECK_LOCATION_2X from './check-location/pic@2x.webp';
import CHECK_LOCATION_3X from './check-location/pic@3x.webp';

import MEMBERS_1X from './members/members.webp';
import MEMBERS_2X from './members/members@2x.webp';
import MEMBERS_3X from './members/members@3x.webp';

import ALERTS_1X from './alerts/pic.webp';
import ALERTS_2X from './alerts/pic@2x.webp';
import ALERTS_3X from './alerts/pic@3x.webp';

import SOS_ALERTS_1X from './sos-alerts/pic.webp';
import SOS_ALERTS_2X from './sos-alerts/pic@2x.webp';
import SOS_ALERTS_3X from './sos-alerts/pic@3x.webp';

import HOME_1X from './place/home.webp';
import HOME_2X from './place/home@2x.webp';
import HOME_3X from './place/home@3x.webp';

import WORK_OLD_1X from './place/work_old.webp';
import WORK_OLD_2X from './place/work_old@2x.webp';
import WORK_OLD_3X from './place/work_old@3x.webp';

import SCHOOL_OLD_1X from './place/school_old.webp';
import SCHOOL_OLD_2X from './place/school_old@2x.webp';
import SCHOOL_OLD_3X from './place/school_old@3x.webp';


import NONE_BIG_OLD_1X from './place/none_big_old.webp';
import NONE_BIG_OLD_2X from './place/none_big_old@2x.webp';
import NONE_BIG_OLD_3X from './place/none_big_old@3x.webp';

import HOME_OLD_1X from './place/home_old.webp';
import HOME_OLD_2X from './place/home_old@2x.webp';
import HOME_OLD_3X from './place/home_old@3x.webp';

import WORK_1X from './place/work.webp';
import WORK_2X from './place/work@2x.webp';
import WORK_3X from './place/work@3x.webp';

import SCHOOL_1X from './place/school.webp';
import SCHOOL_2X from './place/school@2x.webp';
import SCHOOL_3X from './place/school@3x.webp';

import BAR_1X from './place/bar.webp';
import BAR_2X from './place/bar@2x.webp';
import BAR_3X from './place/bar@3x.webp';

import HOBBY_1X from './place/hobby.webp';
import HOBBY_2X from './place/hobby@2x.webp';
import HOBBY_3X from './place/hobby@3x.webp';

import OTHER_LOCATION_1X from './place/location.webp';
import OTHER_LOCATION_2X from './place/location@2x.webp';
import OTHER_LOCATION_3X from './place/location@3x.webp';


import BAR_OLD_1X from './place/bar_old.webp';
import BAR_OLD_2X from './place/bar_old@2x.webp';
import BAR_OLD_3X from './place/bar_old@3x.webp';

import HOBBY_OLD_1X from './place/hobby_old.webp';
import HOBBY_OLD_2X from './place/hobby_old@2x.webp';
import HOBBY_OLD_3X from './place/hobby_old@3x.webp';

import OTHER_LOCATION_OLD_1X from './place/location_old.webp';
import OTHER_LOCATION_OLD_2X from './place/location_old@2x.webp';
import OTHER_LOCATION_OLD_3X from './place/location_old@3x.webp';


import FRIEND_OLD_1X from './place/friend_old.webp';
import FRIEND_OLD_2X from './place/friend_old@2x.webp';
import FRIEND_OLD_3X from './place/friend_old@3x.webp';

import NONE_BIG_1X from './place/none-big.webp';
import NONE_BIG_2X from './place/none-big@2x.webp';
import NONE_BIG_3X from './place/none-big@3x.webp';

// single answers
import SCHEDULE_1X from './following/shedule.webp';
import SCHEDULE_2X from './following/shedule@2x.webp';
import SCHEDULE_3X from './following/shedule@3x.webp';

import DRIVING_1X from './following/driving.webp';
import DRIVING_2X from './following/driving@2x.webp';
import DRIVING_3X from './following/driving@3x.webp';

import VISITED_1X from './following/visited.webp';
import VISITED_2X from './following/visited@2x.webp';
import VISITED_3X from './following/visited@3x.webp';

import BATTERY_1X from './following/battery.webp';
import BATTERY_2X from './following/battery@2x.webp';
import BATTERY_3X from './following/battery@3x.webp';

import SCHEDULE_OLD_1X from './following/schedule_old.webp';
import SCHEDULE_OLD_2X from './following/schedule_old@2x.webp';
import SCHEDULE_OLD_3X from './following/schedule_old@3x.webp';

import DRIVING_OLD_1X from './following/driving_old.webp';
import DRIVING_OLD_2X from './following/driving_old@2x.webp';
import DRIVING_OLD_3X from './following/driving_old@3x.webp';

import VISITED_OLD_1X from './following/visited_old.webp';
import VISITED_OLD_2X from './following/visited_old@2x.webp';
import VISITED_OLD_3X from './following/visited_old@3x.webp';

import BATTERY_OLD_1X from './following/battery_old.webp';
import BATTERY_OLD_2X from './following/battery_old@2x.webp';
import BATTERY_OLD_3X from './following/battery_old@3x.webp';

import NONE_SMALL_OLD_1X from './following/none_small_old.webp';
import NONE_SMALL_OLD_2X from './following/none_small_old@2x.webp';
import NONE_SMALL_OLD_3X from './following/none_small_old@3x.webp';

import CONNECTED_1X from './reason/connected.webp';
import CONNECTED_2X from './reason/connected@2x.webp';
import CONNECTED_3X from './reason/connected@3x.webp';

import DOUBT_1X from './reason/doubt.webp';
import DOUBT_2X from './reason/doubt@2x.webp';
import DOUBT_3X from './reason/doubt@3x.webp';

import PARENTS_1X from './reason/family.webp';
import PARENTS_2X from './reason/family@2x.webp';
import PARENTS_3X from './reason/family@3x.webp';

import KID_1X from './reason/kid.webp';
import KID_2X from './reason/kid@2x.webp';
import KID_3X from './reason/kid@3x.webp';

import MATCH_LOCATION_1X from './reason/match-location.webp';
import MATCH_LOCATION_2X from './reason/match-location@2x.webp';
import MATCH_LOCATION_3X from './reason/match-location@3x.webp';

import SAFETY_1X from './reason/safety.webp';
import SAFETY_2X from './reason/safety@2x.webp';
import SAFETY_3X from './reason/safety@3x.webp';

import NONE_SMALL_1X from './following/none-small.webp';
import NONE_SMALL_2X from './following/none-small@2x.webp';
import NONE_SMALL_3X from './following/none-small@3x.webp';

// transition
import ANXIETY_1X from './anxiety/anxiety.webp';
import ANXIETY_2X from './anxiety/anxiety@2x.webp';
import ANXIETY_3X from './anxiety/anxiety@3x.webp';

import STRAINED_1X from './feeling-strained/strained.webp';
import STRAINED_2X from './feeling-strained/strained@2x.webp';
import STRAINED_3X from './feeling-strained/strained@3x.webp';

import KNOW_1X from './know-location/know.webp';
import KNOW_2X from './know-location/know@2x.webp';
import KNOW_3X from './know-location/know@3x.webp';

import KNOW_OLD_1X from './know-location/know_spoten.webp';
import KNOW_OLD_2X from './know-location/know_spoten@2x.webp';
import KNOW_OLD_3X from './know-location/know_spoten@3x.webp';

import NO_MORE_1X from './no-more/no-more.webp';
import NO_MORE_2X from './no-more/no-more@2x.webp';
import NO_MORE_3X from './no-more/no-more@3x.webp';

import PEACE_1X from './peace-of-mind/peace.webp';
import PEACE_2X from './peace-of-mind/peace@2x.webp';
import PEACE_3X from './peace-of-mind/peace@3x.webp';

// multi
import OTHER_1X from './common/other.webp';
import OTHER_2X from './common/other@2x.webp';
import OTHER_3X from './common/other@3x.webp';

import CALM_1X from './imagine/calm.webp';
import CALM_2X from './imagine/calm@2x.webp';
import CALM_3X from './imagine/calm@3x.webp';

import CURIOUS_1X from './imagine/curious.webp';
import CURIOUS_2X from './imagine/curious@2x.webp';
import CURIOUS_3X from './imagine/curious@3x.webp';

import RELIEVED_1X from './imagine/relieved.webp';
import RELIEVED_2X from './imagine/relieved@2x.webp';
import RELIEVED_3X from './imagine/relieved@3x.webp';

import SECURE_1X from './imagine/secure.webp';
import SECURE_2X from './imagine/secure@2x.webp';
import SECURE_3X from './imagine/secure@3x.webp';

import ANGRY_1X from './questioning-faithfulness/angry.webp';
import ANGRY_2X from './questioning-faithfulness/angry@2x.webp';
import ANGRY_3X from './questioning-faithfulness/angry@3x.webp';

import ANXIOUS_1X from './questioning-faithfulness/anxious.webp';
import ANXIOUS_2X from './questioning-faithfulness/anxious@2x.webp';
import ANXIOUS_3X from './questioning-faithfulness/anxious@3x.webp';

import OVERWHELMED_1X from './questioning-faithfulness/overwhelmed.webp';
import OVERWHELMED_2X from './questioning-faithfulness/overwhelmed@2x.webp';
import OVERWHELMED_3X from './questioning-faithfulness/overwhelmed@3x.webp';

import SICK_1X from './questioning-faithfulness/sick.webp';
import SICK_2X from './questioning-faithfulness/sick@2x.webp';
import SICK_3X from './questioning-faithfulness/sick@3x.webp';

import FAMILY_1X from './common/family.webp';
import FAMILY_2X from './common/family@2x.webp';
import FAMILY_3X from './common/family@3x.webp';

import FRIEND_1X from './common/friend.webp';
import FRIEND_2X from './common/friend@2x.webp';
import FRIEND_3X from './common/friend@3x.webp';

import FRIEND_BIG_1X from './common/friend_big.webp';
import FRIEND_BIG_2X from './common/friend_big@2x.webp';
import FRIEND_BIG_3X from './common/friend_big@3x.webp';

import LOVE_1X from './common/love.webp';
import LOVE_2X from './common/love@2x.webp';
import LOVE_3X from './common/love@3x.webp';

import LOCATION_TRACKING_1X from './features/location_tracking.webp';
import LOCATION_TRACKING_2X from './features/location_tracking@2x.webp';
import LOCATION_TRACKING_3X from './features/location_tracking@3x.webp';

import PLACE_ALERTS_1X from './features/place_alerts.webp';
import PLACE_ALERTS_2X from './features/place_alerts@2x.webp';
import PLACE_ALERTS_3X from './features/place_alerts@3x.webp';

import DRIVING_ROUTES_1X from './features/driving_routes.webp';
import DRIVING_ROUTES_2X from './features/driving_routes@2x.webp';
import DRIVING_ROUTES_3X from './features/driving_routes@3x.webp';

import LOCATION_HISTORY_1X from './features/location_history.webp';
import LOCATION_HISTORY_2X from './features/location_history@2x.webp';
import LOCATION_HISTORY_3X from './features/location_history@3x.webp';

import SOS_1X from './features/sos.webp';
import SOS_2X from './features/sos@2x.webp';
import SOS_3X from './features/sos@3x.webp';

import ALL_1X from './features/all.webp';
import ALL_2X from './features/all@2x.webp';
import ALL_3X from './features/all@3x.webp';

import WRONG_LOCATION_1X from './loved-family-member/wrong-location.webp';
import WRONG_LOCATION_2X from './loved-family-member/wrong-location@2x.webp';
import WRONG_LOCATION_3X from './loved-family-member/wrong-location@3x.webp';

import WEIRD_1X from './loved-family-member/weird.webp';
import WEIRD_2X from './loved-family-member/weird@2x.webp';
import WEIRD_3X from './loved-family-member/weird@3x.webp';

import TRAVEL_1X from './loved-family-member/travel.webp';
import TRAVEL_2X from './loved-family-member/travel@2x.webp';
import TRAVEL_3X from './loved-family-member/travel@3x.webp';

import TEXT_1X from './loved-family-member/text.webp';
import TEXT_2X from './loved-family-member/text@2x.webp';
import TEXT_3X from './loved-family-member/text@3x.webp';

import SOCIAL_MEDIA_1X from './loved-family-member/social-media.webp';
import SOCIAL_MEDIA_2X from './loved-family-member/social-media@2x.webp';
import SOCIAL_MEDIA_3X from './loved-family-member/social-media@3x.webp';

import SAFE_1X from './loved-family-member/safe.webp';
import SAFE_2X from './loved-family-member/safe@2x.webp';
import SAFE_3X from './loved-family-member/safe@3x.webp';

import RISKY_JOB_1X from './loved-family-member/risky-job.webp';
import RISKY_JOB_2X from './loved-family-member/risky-job@2x.webp';
import RISKY_JOB_3X from './loved-family-member/risky-job@3x.webp';

import NOT_ANSWERING_1X from './loved-family-member/not-answering.webp';
import NOT_ANSWERING_2X from './loved-family-member/not-answering@2x.webp';
import NOT_ANSWERING_3X from './loved-family-member/not-answering@3x.webp';

import NORMAL_1X from './loved-family-member/normal.webp';
import NORMAL_2X from './loved-family-member/normal@2x.webp';
import NORMAL_3X from './loved-family-member/normal@3x.webp';

import MEET_1X from './loved-family-member/meet.webp';
import MEET_2X from './loved-family-member/meet@2x.webp';
import MEET_3X from './loved-family-member/meet@3x.webp';

import MEDICAL_1X from './loved-family-member/medical.webp';
import MEDICAL_2X from './loved-family-member/medical@2x.webp';
import MEDICAL_3X from './loved-family-member/medical@3x.webp';

import LIVE_TOGETHER_1X from './loved-family-member/live-together.webp';
import LIVE_TOGETHER_2X from './loved-family-member/live-together@2x.webp';
import LIVE_TOGETHER_3X from './loved-family-member/live-together@3x.webp';

import HIDING_1X from './loved-family-member/hiding.webp';
import HIDING_2X from './loved-family-member/hiding@2x.webp';
import HIDING_3X from './loved-family-member/hiding@3x.webp';

import HANGING_OUT_1X from './loved-family-member/hanging-out.webp';
import HANGING_OUT_2X from './loved-family-member/hanging-out@2x.webp';
import HANGING_OUT_3X from './loved-family-member/hanging-out@2x.webp';

import GETTING_LOST_1X from './loved-family-member/getting-lost.webp';
import GETTING_LOST_2X from './loved-family-member/getting-lost@2x.webp';
import GETTING_LOST_3X from './loved-family-member/getting-lost@3x.webp';

export const IMAGES: ImagesMap = {
  LOVE: {
    src: LOVE_1X,
    srcSet: `${LOVE_1X}, ${LOVE_2X} 2x, ${LOVE_3X} 3x`
  },
  FRIEND_BIG: {
    src: FRIEND_BIG_1X,
    srcSet: `${FRIEND_BIG_1X}, ${FRIEND_BIG_2X} 2x, ${FRIEND_BIG_3X} 3x`
  },
  FRIEND: {
    src: FRIEND_1X,
    srcSet: `${FRIEND_1X}, ${FRIEND_2X} 2x, ${FRIEND_3X} 3x`
  },
  FAMILY: {
    src: FAMILY_1X,
    srcSet: `${FAMILY_1X}, ${FAMILY_2X} 2x, ${FAMILY_3X} 3x`
  },
  // multi
  OTHER: {
    src: OTHER_1X,
    srcSet: `${OTHER_1X}, ${OTHER_2X} 2x, ${OTHER_3X} 3x`
  },
  LOCATION_TRACKING: {
    src: LOCATION_TRACKING_1X,
    srcSet: `${LOCATION_TRACKING_1X}, ${LOCATION_TRACKING_2X} 2x, ${LOCATION_TRACKING_3X} 3x`
  },
  PLACE_ALERTS: {
    src: PLACE_ALERTS_1X,
    srcSet: `${PLACE_ALERTS_1X}, ${PLACE_ALERTS_2X} 2x, ${PLACE_ALERTS_3X} 3x`
  },
  DRIVING_ROUTES: {
    src: DRIVING_ROUTES_1X,
    srcSet: `${DRIVING_ROUTES_1X}, ${DRIVING_ROUTES_2X} 2x, ${DRIVING_ROUTES_3X} 3x`
  },
  LOCATION_HISTORY: {
    src: LOCATION_HISTORY_1X,
    srcSet: `${LOCATION_HISTORY_1X}, ${LOCATION_HISTORY_2X} 2x, ${LOCATION_HISTORY_3X} 3x`
  },
  SOS: {
    src: SOS_1X,
    srcSet: `${SOS_1X}, ${SOS_2X} 2x, ${SOS_3X} 3x`
  },
  ALL: {
    src: ALL_1X,
    srcSet: `${ALL_1X}, ${ALL_2X} 2x, ${ALL_3X} 3x`
  },
  WRONG_LOCATION: {
    src: WRONG_LOCATION_1X,
    srcSet: `${WRONG_LOCATION_1X}, ${WRONG_LOCATION_2X} 2x, ${WRONG_LOCATION_3X} 3x`
  },
  WEIRD: {
    src: WEIRD_1X,
    srcSet: `${WEIRD_1X}, ${WEIRD_2X} 2x, ${WEIRD_3X} 3x`
  },
  TRAVEL: {
    src: TRAVEL_1X,
    srcSet: `${TRAVEL_1X}, ${TRAVEL_2X} 2x, ${TRAVEL_3X} 3x`
  },
  TEXT: {
    src: TEXT_1X,
    srcSet: `${TEXT_1X}, ${TEXT_2X} 2x, ${TEXT_3X} 3x`
  },
  SOCIAL_MEDIA: {
    src: SOCIAL_MEDIA_1X,
    srcSet: `${SOCIAL_MEDIA_1X}, ${SOCIAL_MEDIA_2X} 2x, ${SOCIAL_MEDIA_3X} 3x`
  },
  SAFE: {
    src: SAFE_1X,
    srcSet: `${SAFE_1X}, ${SAFE_2X} 2x, ${SAFE_3X} 3x`
  },
  RISKY_JOB: {
    src: RISKY_JOB_1X,
    srcSet: `${RISKY_JOB_1X}, ${RISKY_JOB_2X} 2x, ${RISKY_JOB_3X} 3x`
  },
  NOT_ANSWERING: {
    src: NOT_ANSWERING_1X,
    srcSet: `${NOT_ANSWERING_1X}, ${NOT_ANSWERING_2X} 2x, ${NOT_ANSWERING_3X} 3x`
  },
  NORMAL: {
    src: NORMAL_1X,
    srcSet: `${NORMAL_1X}, ${NORMAL_2X} 2x, ${NORMAL_3X} 3x`
  },
  MEET: {
    src: MEET_1X,
    srcSet: `${MEET_1X}, ${MEET_2X} 2x, ${MEET_3X} 3x`
  },
  MEDICAL: {
    src: MEDICAL_1X,
    srcSet: `${MEDICAL_1X}, ${MEDICAL_2X} 2x, ${MEDICAL_3X} 3x`
  },
  LIVE_TOGETHER: {
    src: LIVE_TOGETHER_1X,
    srcSet: `${LIVE_TOGETHER_1X}, ${LIVE_TOGETHER_2X} 2x, ${LIVE_TOGETHER_3X} 3x`
  },
  HIDING: {
    src: HIDING_1X,
    srcSet: `${HIDING_1X}, ${HIDING_2X} 2x, ${HIDING_3X} 3x`
  },
  GETTING_LOST: {
    src: GETTING_LOST_1X,
    srcSet: `${GETTING_LOST_1X}, ${GETTING_LOST_2X} 2x, ${GETTING_LOST_3X} 3x`
  },
  HANGING_OUT: {
    src: HANGING_OUT_1X,
    srcSet: `${HANGING_OUT_1X}, ${HANGING_OUT_2X} 2x, ${HANGING_OUT_3X} 3x`
  },
  CALM: {
    src: CALM_1X,
    srcSet: `${CALM_1X}, ${CALM_2X} 2x, ${CALM_3X} 3x`
  },
  CURIOUS: {
    src: CURIOUS_1X,
    srcSet: `${CURIOUS_1X}, ${CURIOUS_2X} 2x, ${CURIOUS_3X} 3x`
  },
  RELIEVED: {
    src: RELIEVED_1X,
    srcSet: `${RELIEVED_1X}, ${RELIEVED_2X} 2x, ${RELIEVED_3X} 3x`
  },
  SECURE: {
    src: SECURE_1X,
    srcSet: `${SECURE_1X}, ${SECURE_2X} 2x, ${SECURE_3X} 3x`
  },
  ANGRY: {
    src: ANGRY_1X,
    srcSet: `${ANGRY_1X}, ${ANGRY_2X} 2x, ${ANGRY_3X} 3x`
  },
  ANXIOUS: {
    src: ANXIOUS_1X,
    srcSet: `${ANXIOUS_1X}, ${ANXIOUS_2X} 2x, ${ANXIOUS_3X} 3x`
  },
  OVERWHELMED: {
    src: OVERWHELMED_1X,
    srcSet: `${OVERWHELMED_1X}, ${OVERWHELMED_2X} 2x, ${OVERWHELMED_3X} 3x`
  },
  SICK: {
    src: SICK_1X,
    srcSet: `${SICK_1X}, ${SICK_2X} 2x, ${SICK_3X} 3x`
  },
  // transition
  ANXIETY: {
    src: ANXIETY_1X,
    srcSet: `${ANXIETY_1X}, ${ANXIETY_2X} 2x, ${ANXIETY_3X} 3x`
  },
  STRAINED: {
    src: STRAINED_1X,
    srcSet: `${STRAINED_1X}, ${STRAINED_2X} 2x, ${STRAINED_3X} 3x`
  },
  KNOW: {
    src: KNOW_1X,
    srcSet: `${KNOW_1X}, ${KNOW_2X} 2x, ${KNOW_3X} 3x`
  },
  KNOW_OLD: {
    src: KNOW_OLD_1X,
    srcSet: `${KNOW_OLD_1X}, ${KNOW_OLD_2X} 2x, ${KNOW_OLD_3X} 3x`
  },
  NO_MORE: {
    src: NO_MORE_1X,
    srcSet: `${NO_MORE_1X}, ${NO_MORE_2X} 2x, ${NO_MORE_3X} 3x`
  },
  PEACE: {
    src: PEACE_1X,
    srcSet: `${PEACE_1X}, ${PEACE_2X} 2x, ${PEACE_3X} 3x`
  },
  // welcome
  LOCATION: {
    src: LOCATION_1X,
    srcSet: `${LOCATION_1X}, ${LOCATION_2X} 2x, ${LOCATION_3X} 3x`
  },
  PLACE: {
    src: PLACE_1X,
    srcSet: `${PLACE_1X}, ${PLACE_2X} 2x, ${PLACE_3X} 3x`
  },
  CRASH: {
    src: CRASH_1X,
    srcSet: `${CRASH_1X}, ${CRASH_2X} 2x, ${CRASH_3X} 3x`
  },
  CHECK_LOCATION: {
    src: CHECK_LOCATION_1X,
    srcSet: `${CHECK_LOCATION_1X}, ${CHECK_LOCATION_2X} 2x, ${CHECK_LOCATION_3X} 3x`
  },
  MEMBERS: {
    src: MEMBERS_1X,
    srcSet: `${MEMBERS_1X}, ${MEMBERS_2X} 2x, ${MEMBERS_3X} 3x`
  },
  ALERTS: {
    src: ALERTS_1X,
    srcSet: `${ALERTS_1X}, ${ALERTS_2X} 2x, ${ALERTS_3X} 3x`
  },
  SOS_ALERTS: {
    src: SOS_ALERTS_1X,
    srcSet: `${SOS_ALERTS_1X}, ${SOS_ALERTS_2X} 2x, ${SOS_ALERTS_3X} 3x`
  },
  HOME: {
    src: HOME_1X,
    srcSet: `${HOME_1X}, ${HOME_2X} 2x, ${HOME_3X} 3x`
  },
  WORK: {
    src: WORK_1X,
    srcSet: `${WORK_1X}, ${WORK_2X} 2x, ${WORK_3X} 3x`
  },
  SCHOOL: {
    src: SCHOOL_1X,
    srcSet: `${SCHOOL_1X}, ${SCHOOL_2X} 2x, ${SCHOOL_3X} 3x`
  },
  HOME_OLD: {
    src: HOME_OLD_1X,
    srcSet: `${HOME_OLD_1X}, ${HOME_OLD_2X} 2x, ${HOME_OLD_3X} 3x`
  },
  WORK_OLD: {
    src: WORK_OLD_1X,
    srcSet: `${WORK_OLD_1X}, ${WORK_OLD_2X} 2x, ${WORK_OLD_3X} 3x`
  },
  SCHOOL_OLD: {
    src: SCHOOL_OLD_1X,
    srcSet: `${SCHOOL_OLD_1X}, ${SCHOOL_OLD_2X} 2x, ${SCHOOL_OLD_3X} 3x`
  },
  NONE_BIG_OLD: {
    src: NONE_BIG_OLD_1X,
    srcSet: `${NONE_BIG_OLD_1X}, ${NONE_BIG_OLD_2X} 2x, ${NONE_BIG_OLD_3X} 3x`
  },
  FRIEND_OLD: {
    src: FRIEND_OLD_1X,
    srcSet: `${FRIEND_OLD_1X}, ${FRIEND_OLD_2X} 2x, ${FRIEND_OLD_3X} 3x`
  },
  BAR_OLD: {
    src: BAR_OLD_1X,
    srcSet: `${BAR_OLD_1X}, ${BAR_OLD_2X} 2x, ${BAR_OLD_3X} 3x`
  },
  HOBBY_OLD: {
    src: HOBBY_OLD_1X,
    srcSet: `${HOBBY_OLD_1X}, ${HOBBY_OLD_2X} 2x, ${HOBBY_OLD_3X} 3x`
  },
  OTHER_LOCATION_OLD: {
    src: OTHER_LOCATION_OLD_1X,
    srcSet: `${OTHER_LOCATION_OLD_1X}, ${OTHER_LOCATION_OLD_2X} 2x, ${OTHER_LOCATION_OLD_3X} 3x`
  },
  BAR: {
    src: BAR_1X,
    srcSet: `${BAR_1X}, ${BAR_2X} 2x, ${BAR_3X} 3x`
  },
  HOBBY: {
    src: HOBBY_1X,
    srcSet: `${HOBBY_1X}, ${HOBBY_2X} 2x, ${HOBBY_3X} 3x`
  },
  OTHER_LOCATION: {
    src: OTHER_LOCATION_1X,
    srcSet: `${OTHER_LOCATION_1X}, ${OTHER_LOCATION_2X} 2x, ${OTHER_LOCATION_3X} 3x`
  },
  NONE_BIG: {
    src: NONE_BIG_1X,
    srcSet: `${NONE_BIG_1X}, ${NONE_BIG_2X} 2x, ${NONE_BIG_3X} 3x`
  },

  // single answers
  SCHEDULE: {
    src: SCHEDULE_1X,
    srcSet: `${SCHEDULE_1X}, ${SCHEDULE_2X} 2x, ${SCHEDULE_3X} 3x`
  },
  DRIVING: {
    src: DRIVING_1X,
    srcSet: `${DRIVING_1X}, ${DRIVING_2X} 2x, ${DRIVING_3X} 3x`
  },
  VISITED: {
    src: VISITED_1X,
    srcSet: `${VISITED_1X}, ${VISITED_2X} 2x, ${VISITED_3X} 3x`
  },
  SCHEDULE_OLD: {
    src: SCHEDULE_OLD_1X,
    srcSet: `${SCHEDULE_OLD_1X}, ${SCHEDULE_OLD_2X} 2x, ${SCHEDULE_OLD_3X} 3x`
  },
  DRIVING_OLD: {
    src: DRIVING_OLD_1X,
    srcSet: `${DRIVING_OLD_1X}, ${DRIVING_OLD_2X} 2x, ${DRIVING_OLD_3X} 3x`
  },
  VISITED_OLD: {
    src: VISITED_OLD_1X,
    srcSet: `${VISITED_OLD_1X}, ${VISITED_OLD_2X} 2x, ${VISITED_OLD_3X} 3x`
  },
  NONE_SMALL_OLD: {
    src: NONE_SMALL_OLD_1X,
    srcSet: `${NONE_SMALL_OLD_1X}, ${NONE_SMALL_OLD_2X} 2x, ${NONE_SMALL_OLD_3X} 3x`
  },
  BATTERY: {
    src: BATTERY_1X,
    srcSet: `${BATTERY_1X}, ${BATTERY_2X} 2x, ${BATTERY_3X} 3x`
  },
  BATTERY_OLD: {
    src: BATTERY_OLD_1X,
    srcSet: `${BATTERY_OLD_1X}, ${BATTERY_OLD_2X} 2x, ${BATTERY_OLD_3X} 3x`
  },
  CONNECTED: {
    src: CONNECTED_1X,
    srcSet: `${CONNECTED_1X}, ${CONNECTED_2X} 2x, ${CONNECTED_3X} 3x`
  },
  DOUBT: {
    src: DOUBT_1X,
    srcSet: `${DOUBT_1X}, ${DOUBT_2X} 2x, ${DOUBT_3X} 3x`
  },
  PARENTS: {
    src: PARENTS_1X,
    srcSet: `${PARENTS_1X}, ${PARENTS_2X} 2x, ${PARENTS_3X} 3x`
  },
  KID: {
    src: KID_1X,
    srcSet: `${KID_1X}, ${KID_2X} 2x, ${KID_3X} 3x`
  },
  MATCH_LOCATION: {
    src: MATCH_LOCATION_1X,
    srcSet: `${MATCH_LOCATION_1X}, ${MATCH_LOCATION_2X} 2x, ${MATCH_LOCATION_3X} 3x`
  },
  SAFETY: {
    src: SAFETY_1X,
    srcSet: `${SAFETY_1X}, ${SAFETY_2X} 2x, ${SAFETY_3X} 3x`
  },
  NONE_SMALL: {
    src: NONE_SMALL_1X,
    srcSet: `${NONE_SMALL_1X}, ${NONE_SMALL_2X} 2x, ${NONE_SMALL_3X} 3x`
  },
};