import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs'

import Analytics from '@web-solutions/module-analytics';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { Title } from '@web-solutions/core/ui-elements';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';

import { t } from '../../../localization';

import { selectSubscription, useSliceDispatch, goAppLink, reactivate } from '../../slice';
import { ROUTES } from '../../constants/routes';
import { ReactComponent as ArrowBackIcon } from '../../components/icons/arrow-back.svg';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { SuccessModal } from '../../components/success-modal';
import { useManageContext } from '../../hooks/use-manage-context';
import { SubscriptionStatus } from '../../types';

import { CurrentPlan } from './current-plan';
import { Features } from './features';
import { Info } from './info';

import classes from './style.module.scss';
import { Footer } from './footer';

import '@web-solutions/core/containers/page-transition/styles.scss'

const tKey = 'manage.main';
const analytics = 'manage_main';

export const ManageMainPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { mngSubMainPage } = useRemoteConfig();
  const { mainContent } = useManageContext();

  const [stage, setStage] = useState<'plan' | 'info'>('plan');

  const [success, setSuccess] = useState(false);
  const [statusCode, setStatusCode] = useState('');
  const [error, setError] = useState('');

  const { navigateNextScreen } = useNavigateManage();

  const subscription = useSelector(selectSubscription);

  useEffect(() => {
    if (mngSubMainPage.planDetailsEnabled) {
      Analytics.trackEvent(analytics, 'open', { screen: stage });
    } else {
      Analytics.trackEvent(analytics, 'open');
    }
  }, [mngSubMainPage.planDetailsEnabled, stage]);

  const handleReactivateClick = () => {
    Analytics.trackEvent('manage_reactivate', 'submit', { productId: subscription?.product });
    dispatch(reactivate())
      .unwrap()
      .then(() => {
        Analytics.trackEvent('manage_reactivate', 'success', { productId: subscription?.product });
        setSuccess(true);
      })
      .catch((e) => {
        Analytics.trackEvent('manage_reactivate', 'error', { productId: subscription?.product, code: e?.code, message: e?.message });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code)
      });
  }

  const handleChangePlanClick = () => {
    navigate({
      pathname: ROUTES.PLANS,
      search: location.search,
    },
      {
        state: {
          fromMain: true,
        }
      });
  };

  const handleCancelSubscriptionClick = () => {
    Analytics.trackEvent(analytics, 'continue');
    navigateNextScreen();
  };

  const handleReturnToAppClick = () => {
    Analytics.trackEvent(analytics, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${analytics}_error_modal`, 'close');
    setError('');
    navigateNextScreen();
  }

  const handleBackClick = () => {
    if (stage === 'info') {
      Analytics.trackEvent(analytics, 'back', { screen: 'info' });
      setStage('plan')
    } else {
      Analytics.trackEvent(analytics, 'back_to_app', { screen: 'plan' });
      dispatch(goAppLink());
    }
  }

  const handleCancel = () => {
    Analytics.trackEvent(analytics, 'continue', { screen: 'plan' });
    setStage('info');
  }

  const isPlanDetailsAvailable = mngSubMainPage.planDetailsEnabled && subscription && !(subscription?.status === SubscriptionStatus.CANCELED || subscription?.status === SubscriptionStatus.EXPIRED)
  const isSubUpdating = Boolean(mngSubMainPage?.updatingDelay && subscription?.created_at && dayjs.utc(subscription?.created_at).add(mngSubMainPage?.updatingDelay, 'minutes').diff(dayjs.utc(), 'seconds') >= 0)

  return (
    <SwitchTransition>
      <CSSTransition key={stage} timeout={400} classNames="page-fade">
        <>
          {isPlanDetailsAvailable ?
            <div className={classes.wrap}>
              <button className={classes.back_button} onClick={handleBackClick}>
                <ArrowBackIcon />
              </button>
              {stage === 'plan' && <>
                <Title level={'h1'}>{t(`${tKey}.plan_title`)}</Title>
                <CurrentPlan
                  isSubUpdating={isSubUpdating}
                  isPlanDetailsAvailable={isPlanDetailsAvailable}
                  onCancelClick={handleCancel}
                />
                {mainContent}
                {Boolean(mngSubMainPage?.updatingDelay) && <Info />}
              </>}
              {stage === 'info' && <>
                <>
                  <Features />
                  <Footer
                    tKey={tKey}
                    onCancelSubscriptionClick={handleCancelSubscriptionClick}
                    onChangePlanClick={handleChangePlanClick}
                    onReactivateClick={handleReactivateClick}
                    onReturnToAppClick={handleReturnToAppClick}
                  />
                </>
              </>}
            </div>
            :
            <div className={classes.wrap}>
              <Title level={'h1'}>{
                mngSubMainPage.planDetailsEnabled ?
                  t(`${tKey}.plan_title`)
                  :
                  t(`${tKey}.title`)}
              </Title>
              {subscription
                ? (
                  <>
                    <CurrentPlan />
                    <Features />
                    <Footer
                      tKey={tKey}
                      onCancelSubscriptionClick={handleCancelSubscriptionClick}
                      onChangePlanClick={handleChangePlanClick}
                      onReactivateClick={handleReactivateClick}
                      onReturnToAppClick={handleReturnToAppClick}
                    />
                  </>

                )
                : (
                  <>
                    <Features />
                    <Footer
                      tKey={tKey}
                      onCancelSubscriptionClick={handleCancelSubscriptionClick}
                      onChangePlanClick={handleChangePlanClick}
                      onReactivateClick={handleReactivateClick}
                      onReturnToAppClick={handleReturnToAppClick}
                    />
                  </>
                )}
            </div>
          }
          <SuccessModal
            category="manage_reactivate_success_modal"
            isOpen={success}
            tKey={`manage.plan.reactivated_modal`}
          />

          <ErrorPopup
            visible={!!error}
            onClose={handleCloseErrorClick}
            onSubmit={handleCloseErrorClick}
            buttonTitle={`OK`}
            statusCode={statusCode}
          />
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};
