import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Famsy';
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZGlnaXRhbC1zb2x1dGlvbnMiLCJhIjoiY2ptajd2MjdwMDU4bTNwbXQzcHBlcWU4ZSJ9._A_elSsQ6-6IqBkh4k5VMQ';
export const LOCATION_URL = 'https://spoten-api.digisoapps.com/api/v3/profile/approximate-location';
export const STYLE = 'https://spoten-api.digisoapps.com/maps/style';

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://familyspots.go.link/p',
  ADJUST_TOKEN: '11rcy7e3',
};
