import { SPOTEN_FIND_WHO_ANSWERS } from 'src/constants/screens';

import * as TYPES from './types';

type Location = {
  lat: number;
  lon: number;
  zoom: number;
}

type Phone = string;

export type State = {
  phone: Phone;
  location: Location | null;
  member?: SPOTEN_FIND_WHO_ANSWERS;
}

type Action = {
  type: string;
  payload: Phone | Location
}

export const initialState: State = {
  phone: '',
  location: null,
  member: SPOTEN_FIND_WHO_ANSWERS.friend,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case TYPES.SET_MEMBER:
      return {
        ...state,
        member: action.payload
      }
    case TYPES.SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case TYPES.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
