import { configureStore, createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';
import { remoteConfigSelector } from '@web-solutions/core/store/remote-config/selectors';
import { reInit as reInitRemoteConfig } from '@web-solutions/core/store/remote-config/actions';

import { PaymentSystem, ProductInfo } from '@web-solutions/react-billing';

//@ts-ignore
import Billing from '@web-solutions/module-billing';
import Attribution from '@web-solutions/module-attribution';
import { init as initBilling, fetchProducts, resetPurchase, getPaymentMethod } from '@web-solutions/core/store/billing/actions';
import { Currencies } from '@web-solutions/react-billing/constants';
import { get3DSRedirectURLs } from '@web-solutions/core/utils/billing'

import { SubscriptionStatus } from './types';

interface State {
  pending: boolean,
  ready: boolean,
  paymentSystem: PaymentSystem | null,
  subscription: {
    id: number,
    product: string,
    active: boolean,
    status: SubscriptionStatus,
    payment_service: PaymentSystem,
    email: string,
    created_at: string,
    external_id: string,
    expire_at: string,
    trial_end_at: string,
    canceled_at: string,
    sig: string,
  } | null,
  product: ProductInfo | null,
  offers: ProductInfo[] | null,
  appLink: string,
}

const initialState: State = {
  pending: false,
  ready: false,
  paymentSystem: null,
  subscription: null,
  product: null,
  offers: null,
  appLink: '',
};

export const slice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setPaymentSystem: (state, action: PayloadAction<State['paymentSystem']>) => {
      state.paymentSystem = action.payload;
    },
    setSubscription: (state, action: PayloadAction<State['subscription']>) => {
      state.subscription = action.payload;
    },
    setProduct: (state, action: PayloadAction<State['product']>) => {
      state.product = action.payload;
    },
    setAppLink: (state, action: PayloadAction<string>) => {
      state.appLink = action.payload;
    },
    goAppLink: (state) => {
      window.location.href = state.appLink;
    },
    setOffers: (state, action: PayloadAction<State['offers']>) => {
      state.offers = action.payload;
    },
  },
})

const { setPending, setReady, setPaymentSystem, setSubscription, setProduct, setAppLink, setOffers } = slice.actions;
export const { goAppLink } = slice.actions;

export const init = createAsyncThunk(
  `${slice.name}/init`,
  async (args: { isAsb?: boolean } | undefined, { dispatch, getState }) => {
    dispatch(setPending(true));
    try {
      await dispatch(initBilling());

      const subscriptions = ((await Billing.getSubscriptions(args?.isAsb))?.data || []) as Array<State['subscription']>;
      const res = await dispatch(getPaymentMethod());
      Analytics.setUserProperty('paymentMethod', res);
      await dispatch(reInitRemoteConfig());
      const subscription = subscriptions.find(i => !i!.canceled_at) || subscriptions.find(i => i!.active) || subscriptions.pop();
      if (subscription) {
        if (subscription.active) {
          if (subscription.canceled_at) {
            subscription.status = SubscriptionStatus.CANCELED;
          } else if (subscription.trial_end_at === subscription.expire_at) {
            subscription.status = SubscriptionStatus.TRIAL;
          } else {
            subscription.status = SubscriptionStatus.ACTIVE;
          }
        } else {
          subscription.status = SubscriptionStatus.EXPIRED;
        }

        dispatch(setSubscription(subscription));
        dispatch(setPaymentSystem((subscription as any).payment_service as PaymentSystem));

        const { mngSubOfferFreePeriodPage, mngSubOfferCheapProductPage } = remoteConfigSelector(getState());

        const mngSubOfferFreePeriodPageProducts = Currencies.reduce((prev: any, curr) => {
          if (mngSubOfferFreePeriodPage[curr]?.productId) {
            return [...prev, { id: mngSubOfferFreePeriodPage[curr]?.productId }]
          }
          return prev
        }, [])

        const mngSubOfferCheapProductPageProducts = Currencies.reduce((prev: any, curr) => {
          if (mngSubOfferCheapProductPage[curr]?.productId) {
            return [...prev, { id: mngSubOfferCheapProductPage[curr]?.productId }]
          }
          return prev
        }, [])

        const products = (await dispatch(fetchProducts([{ id: subscription.product }, ...mngSubOfferFreePeriodPageProducts, ...mngSubOfferCheapProductPageProducts])));

        const product = products?.find(product => product.id === subscription.product);
        if (product !== undefined) {
          dispatch(setProduct(product));
        }

        dispatch(setOffers(products?.filter(product => product.id !== subscription.product)));
      }

      const appLink = await Attribution.getLinkToApp()
      dispatch(setAppLink(appLink));
    } finally {
      dispatch(setPending(false));
      dispatch(setReady(true));
    }
  }
)

export const changePlan = createAsyncThunk(
  `${slice.name}/changePlan`,
  async ({ productId, isNewSubscription }: { productId: string | number, isNewSubscription?: boolean }, { dispatch, getState }) => {
    dispatch(setPending(true));
    try {
      const subscription = selectSubscription(getState());

      const { failUrl, successUrl } = get3DSRedirectURLs()

      const r = ((await Billing.changePlan({ subscriptionId: subscription?.id, productId, isNewSubscription, successUrl, failUrl })) || {});

      console.log(r);

      return r
    } finally {
      dispatch(setPending(false));
    }
  }
)

export const reactivate = createAsyncThunk(
  `${slice.name}/reactivate`,
  async (a, { dispatch, getState }) => {
    const subscription = selectSubscription(getState());
    return dispatch(changePlan({ productId: subscription?.product! }))
      .unwrap()
      .catch((err) => {
        if (err?.message === 'Plan changing is fail. The same plan used') {
          return;
        } else {
          throw err;
        }
      });
  }
)

export const cancelSubscription = createAsyncThunk(
  `${slice.name}/cancelSubscription`,
  async ({ withFreeGift }: { withFreeGift?: boolean }, { dispatch, getState }) => {
    dispatch(setPending(true));

    const subscription = selectSubscription(getState());

    try {
      const r = await Billing.submitCancelSubscription({
        sig: subscription?.sig,
        email: subscription?.email,
        ...(withFreeGift ? { gift: 1 } : {})
      });
      dispatch(resetPurchase());
      return r;
    } finally {
      dispatch(setPending(false));
    }
  }
)

export const selectPending = (state: any) => (state[slice.name] as State).pending;
export const selectReady = (state: any) => (state[slice.name] as State).ready;
export const selectSubscription = (state: any) => (state[slice.name] as State).subscription;
export const selectProduct = (state: any) => (state[slice.name] as State).product;
export const selectPaymentSystem = (state: any) => (state[slice.name] as State).paymentSystem;
export const selectAppLink = (state: any) => (state[slice.name] as State).appLink;
export const selectOffers = (state: any) => (state[slice.name] as State).offers;

const store = configureStore({
  reducer: slice.reducer,
})

export type AppDispatch = typeof store.dispatch
export const useSliceDispatch: () => AppDispatch = useDispatch;
