import { QuizStepKind } from '@web-solutions/core/containers/questions/types';

import { QuizStep } from '@web-solutions/base-app/screens/quiz/interfaces';

import { QUIZ } from 'src/constants/routes';
import { SPOTEN_FIND_WHO_ANSWERS } from 'src/constants/screens';

import { IMAGES } from './images';

export const STEPS: { [key: string]: QuizStep } = {
  [QUIZ.SPOTEN_FEEL_ANXIOUS]: {
    type: 'single',
    title: 'I often feel anxious when my partner goes out without me.',
    uptitle: 'Does the statement below characterize you?',
    kind: QuizStepKind.Numeric,
  },
  [QUIZ.SPOTEN_FEEL_HIDING]: {
    type: 'single',
    title: 'I often feel like my partner is hiding something from me.',
    uptitle: 'Does the statement below characterize you?',
    kind: QuizStepKind.Numeric,
  },
  [QUIZ.SPOTEN_SUSPECT_CHEATING]: {
    type: 'single',
    title: 'I often suspect that my partner is cheating on me.',
    uptitle: 'Does the statement below characterize you?',
    kind: QuizStepKind.Numeric,
  },
  [QUIZ.SPOTEN_MORE_DISTANT]: {
    type: 'single',
    uptitle: 'Does the statement below characterize your partner’s behavior?',
    title: 'My partner seems to be more distant and less affectionate.',
    kind: QuizStepKind.Bipolar,
  },
  [QUIZ.SPOTEN_MORE_TIME_AWAY]: {
    type: 'single',
    uptitle: 'Does the statement below characterize your partner’s behavior?',
    title: 'My partner has started spending more time away from home.',
    kind: QuizStepKind.Bipolar,
  },
  [QUIZ.SPOTEN_MORE_SECRETIVE]: {
    type: 'single',
    uptitle: 'Does the statement below characterize your partner’s behavior?',
    title: 'My partner has become more secretive about their whereabouts.',
    kind: QuizStepKind.Bipolar,
  },
  [QUIZ.SPOTEN_KNOWING_WHERE]: {
    type: 'single',
    uptitle: 'Do you relate to the following statement?',
    title: 'Always knowing where my partner is and making sure they are telling the truth will bring me a sense of calm.',
    kind: QuizStepKind.Bipolar,
  },
  [QUIZ.SPOTEN_GENDER]: {
    type: 'single',
    title: 'What’s your gender?',
    options: {
      "male": "Male",
      "female": "Female",
      "non": "Non-binary",
      "other": "Other",
    },
  },
  [QUIZ.SPOTEN_CLOSE_RELATIONSHIPS]: {
    type: 'single',
    title: 'Do you feel you have a close, trusting relationship with your partner?',
    options: {
      "yes": "Yes",
      "no": "No",
      "other": "Not sure",
    },
  },
  [QUIZ.SPOTEN_BEHAVIOR_CHANGE]: {
    type: 'single',
    title: 'Have you noticed a change in your partner’s behavior recently?',
    options: {
      "yes": "Yes",
      "no": "No",
      "other": "Not sure",
    },
  },
  [QUIZ.SPOTEN_MORE_THAN_USUAL]: {
    type: 'single',
    title: 'Have you noticed your partner talking or texting on their phone more than usual?',
    options: {
      "yes": "Yes",
      "no": "No",
      "other": "Not sure",
    },
  },
  [QUIZ.SPOTEN_DELETING_MESSAGES]: {
    type: 'single',
    title: 'Have you noticed your partner deleting messages or clearing call logs on their phone?',
    options: {
      "yes": "Yes",
      "no": "No",
      "other": "Not sure",
    },
  },
  [QUIZ.SPOTEN_ACTING_DIFFERENTLY]: {
    type: 'single',
    title: 'Have you noticed your partner acting differently when talking about certain people?',
    options: {
      "yes": "Yes",
      "no": "No",
      "other": "Not sure",
    },
  },
  [QUIZ.SPOTEN_QUESTIONING_FAITHFULNESS]: {
    type: 'multi',
    title: 'Constantly questioning your partner’s faithfulness makes you feel…',
    options: {
      "overwhelmed": "Overwhelmed and exhausted",
      "anxious": "Anxious and panicky",
      "angry": "Angry and resentful",
      "sick": "Physically sick",
      "other": "Other",
    },
    images: {
      "overwhelmed": IMAGES.OVERWHELMED,
      "anxious": IMAGES.ANXIOUS,
      "angry": IMAGES.ANGRY,
      "sick": IMAGES.SICK,
      "other": IMAGES.OTHER,
    },
  },
  [QUIZ.SPOTEN_IMAGINE]: {
    type: 'multi',
    title: 'Imagine you’ve found a way to track your partner’s location and get instant alerts on their whereabouts. How do you feel?',
    options: {
      "relieved": "Relieved and hopeful",
      "curious": "Curious and excited",
      "secure": "Secure and in control",
      "calm": "Calm and free from worries",
      "other": "Other",
    },
    images: {
      "relieved": IMAGES.RELIEVED,
      "curious": IMAGES.CURIOUS,
      "secure": IMAGES.SECURE,
      "calm": IMAGES.CALM,
      "other": IMAGES.OTHER,
    },
  },
  [QUIZ.SPOTEN_TRANSITION_FEELING_STRAINED]: {
    type: 'transition',
    title: 'Has your relationship been\nfeeling strained lately?',
    subTitle: 'It’s not easy to have peace of mind when your partner is away, and you don’t know where they are',
    description: 'Suspicions and doubts can take over and make it harder to trust',
    image: IMAGES.STRAINED,
  },
  [QUIZ.SPOTEN_TRANSITION_ANXIETY]: {
    type: 'transition',
    title: 'Are you struggling with\nconstant anxiety?',
    subTitle: 'It can be an exhausting experience, always looking for clues to see if your partner is being faithful',
    description: 'You might find yourself asking questions like, “Aren’t they lying to me?”, “Are they really where they said they were?”',
    image: IMAGES.ANXIETY,
  },
  [QUIZ.SPOTEN_TRANSITION_PEACE_OF_MIND]: {
    type: 'transition',
    title: 'You deserve the peace of\nmind',
    subTitle: 'Stop wasting time and energy wondering about your partner’s actions',
    description: 'There’s a way to feel more secure and in control of the situation',
    image: IMAGES.PEACE,
  },
  [QUIZ.SPOTEN_TRANSITION_NO_MORE]: {
    type: 'transition',
    title: 'No more sleepless nights\nand false suspicions',
    subTitle: 'It’s time to say goodbye to the constant worrying and feeling of uncertainty',
    description: 'With Spoten, you’ll be able to put your trust issues to the test',
    image: IMAGES.NO_MORE,
  },
  [QUIZ.SPOTEN_TRANSITION_KNOW_LOCATION]: {
    type: 'transition',
    title: 'Safety first with\n Famsy',
    subTitle: 'Track your dear one’s location and get instant alerts once they’re far off track',
    description: 'You’ll have peace of mind knowing where exactly they are at any time',
    image: IMAGES.KNOW,
  },
  [QUIZ.SPOTEN_TRANSITION_KNOW_LOCATION_OLD]: {
    type: 'transition',
    title: 'Safety first with\n Famsy',
    subTitle: 'Track your dear one’s location and get instant alerts once they’re far off track',
    description: 'You’ll have peace of mind knowing where exactly they are at any time',
    image: IMAGES.KNOW_OLD,
  },
  [QUIZ.SPOTEN_WELCOME_LOCATION]: {
    type: 'welcome',
    title: 'Check Location and History',
    subTitle: 'Know where your contacts are with live location updates',
    image: IMAGES.LOCATION,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_WELCOME_ALERTS]: {
    type: 'welcome',
    title: 'Place Alerts',
    subTitle: 'Be notified when they leave or arrive home',
    image: IMAGES.ALERTS,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_SOS_ALERTS]: {
    type: 'welcome',
    title: 'SOS Alerts',
    subTitle: 'Instantly be notified in case of emergency',
    image: IMAGES.SOS_ALERTS,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_FIND_WHO]: {
    type: 'single',
    title: 'Whom would you like to locate?',
    options: {
      [SPOTEN_FIND_WHO_ANSWERS.loved]: "Loved One",
      [SPOTEN_FIND_WHO_ANSWERS.friend]: "Friend",
      [SPOTEN_FIND_WHO_ANSWERS.member]: "Family Member",
    },
    images: {
      [SPOTEN_FIND_WHO_ANSWERS.loved]: IMAGES.LOVE,
      [SPOTEN_FIND_WHO_ANSWERS.friend]: IMAGES.FRIEND,
      [SPOTEN_FIND_WHO_ANSWERS.member]: IMAGES.FAMILY,
    }
  },
  [QUIZ.SPOTEN_FIND_WHO_OLD]: {
    type: 'single',
    title: 'Whom would you like to locate?',
    options: {
      [SPOTEN_FIND_WHO_ANSWERS.loved]: "Loved One",
      [SPOTEN_FIND_WHO_ANSWERS.friend]: "Friend",
      [SPOTEN_FIND_WHO_ANSWERS.member]: "Family Member",
    },
  },
  SPOTEN_REASON_MEMBER: {
    type: 'single',
    title: 'What is your primary reason for wanting to track Family Member’s location?',
    options: {
      "safety": "Safety concerns",
      "connected": "I want to stay connected",
      "kids": "Keep kid away from bad places and companies",
      "family": "Prevent grandparent from getting lost",
      "none": "Other",
    },
    images: {
      "safety": IMAGES.SAFETY,
      "connected": IMAGES.CONNECTED,
      "kids": IMAGES.KID,
      "family": IMAGES.PARENTS,
      "none": IMAGES.OTHER,
    }
  },
  SPOTEN_REASON_FRIEND: {
    type: 'single',
    title: 'What is your primary reason for wanting to track Friend’s location?',
    options: {
      "safety": "Safety concerns",
      "connected": "I want to stay connected",
      "match_location": "Match friend’s location to meet more often",
      "none": "Other",
    },
    images: {
      "safety": IMAGES.SAFETY,
      "connected": IMAGES.CONNECTED,
      "match_location": IMAGES.MATCH_LOCATION,
      "none": IMAGES.OTHER,
    }
  },
  SPOTEN_REASON_LOVED: {
    type: 'single',
    title: 'What is your primary reason for wanting to track loved one’s location?',
    options: {
      "doubt": "I doubt my partner’s honesty about location",
      "connected": "I want to stay connected",
      "safety": "Safety concerns",
      "none": "Other",
    },
    images: {
      "doubt": IMAGES.DOUBT,
      "connected": IMAGES.CONNECTED,
      "safety": IMAGES.SAFETY,
      "none": IMAGES.OTHER,
    }
  },
  SPOTEN_PLACE_FRIEND: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "work": "Work",
      "school": "School/University",
      "bar": "Bar & Restaurants",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME,
      "work": IMAGES.WORK,
      "bar": IMAGES.BAR,
      "school": IMAGES.SCHOOL,
      "none": IMAGES.NONE_BIG,
    },
  },
  SPOTEN_PLACE_LOVED: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "work": "Work",
      "home": "Home",
      "friends": "Friends",
      "bar": "Bar & Restaurants",
      "unknown": "Unknown Locations",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME,
      "work": IMAGES.WORK,
      "friends": IMAGES.FRIEND_BIG,
      "bar": IMAGES.BAR,
      "unknown": IMAGES.OTHER_LOCATION,
      "none": IMAGES.NONE_BIG,
    },
  },
  SPOTEN_PLACE_MEMBER: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "school": "School",
      "friends": "Friends",
      "hobby": "Hobby classes",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME,
      "hobby": IMAGES.HOBBY,
      "friends": IMAGES.FRIEND_BIG,
      "school": IMAGES.SCHOOL,
      "none": IMAGES.NONE_BIG,
    },
  },
  SPOTEN_PLACE_OLD_FRIEND: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "work": "Work",
      "school": "School/University",
      "bar": "Bar & Restaurants",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME_OLD,
      "work": IMAGES.WORK_OLD,
      "bar": IMAGES.BAR_OLD,
      "school": IMAGES.SCHOOL_OLD,
      "none": IMAGES.NONE_BIG_OLD,
    },
  },
  SPOTEN_PLACE_OLD_LOVED: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "work": "Work",
      "home": "Home",
      "friends": "Friends",
      "bar": "Bar & Restaurants",
      "unknown": "Unknown Locations",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME_OLD,
      "work": IMAGES.WORK_OLD,
      "friends": IMAGES.FRIEND_OLD,
      "bar": IMAGES.BAR_OLD,
      "unknown": IMAGES.OTHER_LOCATION_OLD,
      "none": IMAGES.NONE_BIG_OLD,
    },
  },
  SPOTEN_PLACE_OLD_MEMBER: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "school": "School",
      "friends": "Friends",
      "hobby": "Hobby classes",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME_OLD,
      "hobby": IMAGES.HOBBY_OLD,
      "friends": IMAGES.FRIEND_OLD,
      "school": IMAGES.SCHOOL_OLD,
      "none": IMAGES.NONE_BIG_OLD,
    },
  },
  [QUIZ.SPOTEN_PLACE]: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "work": "Work",
      "school": "School/University",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME,
      "work": IMAGES.WORK,
      "school": IMAGES.SCHOOL,
      "none": IMAGES.NONE_BIG,
    },
  },
  [QUIZ.SPOTEN_PLACE_OLD]: {
    type: 'multi',
    title: 'Which places you’d like to get alerts for when they visit?',
    kind: QuizStepKind.Square,
    options: {
      "home": "Home",
      "work": "Work",
      "school": "School/University",
      "none": "Other",
    },
    images: {
      "home": IMAGES.HOME_OLD,
      "work": IMAGES.WORK_OLD,
      "school": IMAGES.SCHOOL_OLD,
      "none": IMAGES.NONE_BIG_OLD,
    },
  },
  [QUIZ.SPOTEN_FEATURES]: {
    type: 'multi',
    title: 'Which features are most essential for ensuring the safety of your member',
    options: {
      "location_tracking": "Real-time location tracking",
      "place_alerts": "Place alerts",
      "driving_routes": "Detailed driving routes",
      "location_history": "Location history",
      "sos": "SOS button",
      "all": "All of the above",
      "none": "Other",
    },
    images: {
      "location_tracking": IMAGES.LOCATION_TRACKING,
      "place_alerts": IMAGES.PLACE_ALERTS,
      "driving_routes": IMAGES.DRIVING_ROUTES,
      "location_history": IMAGES.LOCATION_HISTORY,
      "sos": IMAGES.SOS,
      "all": IMAGES.ALL,
      "none": IMAGES.OTHER,
    },
  },
  [QUIZ.SPOTEN_HOW_MANY_PEOPLE]: {
    type: 'single',
    title: 'How many people do you want to track with Famsy?',
    options: {
      "1-2": "1-2",
      "3-5": "3-5",
      "6-10": "6-10",
      "more10": "More than 10"
    },
  },
  [QUIZ.SPOTEN_LOVED_BEHAVIOR_CHANGE]: {
    type: 'single',
    title: 'Have you noticed a change in your loved one’s behavior recently?',
    options: {
      "yes": "Yes, they seem to be hiding something",
      "not_really": "Not really, but I want to make sure they're safe",
      "no": "No, everything seems normal",
    },
    images: {
      "yes": IMAGES.HIDING,
      "not_really": IMAGES.SAFE,
      "no": IMAGES.NORMAL,
    }
  },
  [QUIZ.SPOTEN_LOVED_CHEATING]: {
    type: 'single',
    title: 'Do you suspect your loved one is cheating on you?',
    options: {
      "yes": "Yes, I've had my doubts before",
      "maybe": "Maybe, I want to find out the truth",
      "no": "No, I trust them 100%",
      "not_say": "I'd rather not say"
    },
  },
  [QUIZ.SPOTEN_CRASH_DETECTION]: {
    type: 'welcome',
    title: 'Crash Detection',
    subTitle: 'Get instant car crash alerts',
    image: IMAGES.CRASH,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_CHECK_LOCATION]: {
    type: 'welcome',
    title: 'Check Location and History',
    subTitle: 'Know where your contacts are with live location updates',
    image: IMAGES.CHECK_LOCATION,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_PLACE_ALERTS]: {
    type: 'welcome',
    title: 'Place Alerts',
    subTitle: 'Be notified when they leave or arrive home',
    image: IMAGES.PLACE,
    buttonText: 'Continue',
  },
  [QUIZ.SPOTEN_LOVED_WORRIES_MOST]: {
    type: 'single',
    title: 'What worries you the most?',
    options: {
      "not_answering": "They're not answering my calls or messages",
      "not_where_said": "They're not where they said they would be",
      "people_dont_know": "They're hanging out with people I don't know",
      "safe": "I just want to make sure they're safe"
    },
    images: {
      "not_answering": IMAGES.NOT_ANSWERING,
      "not_where_said": IMAGES.WRONG_LOCATION,
      "people_dont_know": IMAGES.HANGING_OUT,
      "safe": IMAGES.SAFE,
    }
  },
  [QUIZ.SPOTEN_MEMBER_KEEP_CONTACT]: {
    type: 'single',
    title: 'How do you currently keep in contact with them?',
    options: {
      "live_together": "We live together",
      "meet_reguraly": "We meet regularly",
      "call_or_text": "We call or text each other",
      "social_media": "We follow each other on social media",
      "other": "Other",
    },
    images: {
      "live_together": IMAGES.LIVE_TOGETHER,
      "meet_reguraly": IMAGES.MEET,
      "call_or_text": IMAGES.TEXT,
      "social_media": IMAGES.SOCIAL_MEDIA,
      "other": IMAGES.OTHER,
    }
  },
  [QUIZ.SPOTEN_MEMBER_BEHAVIOR_CHANGE]: {
    type: 'single',
    title: 'Have you noticed a change in their behavior recently?',
    options: {
      "yes": "Yes, and I'm worried about them",
      "not_really": "Not really, but I want to make sure they're safe",
      "no": "No, everything seems normal",
    },
    images: {
      "yes": IMAGES.DOUBT,
      "not_really": IMAGES.SAFE,
      "no": IMAGES.NORMAL,
    }
  },
  [QUIZ.SPOTEN_MEMBER_WORRIES_MOST]: {
    type: 'single',
    title: 'What worries you the most?',
    options: {
      "risky_job": "They have a risky job",
      "travel_alone": "They often travel alone",
      "weird_people": "They hang out with weird people",
      "medical": "They have a medical condition",
      "getting_lost": "They have a history of getting lost",
      "other": "Other",
    },
    images: {
      "risky_job": IMAGES.RISKY_JOB,
      "travel_alone": IMAGES.TRAVEL,
      "weird_people": IMAGES.WEIRD,
      "medical": IMAGES.MEDICAL,
      "getting_lost": IMAGES.GETTING_LOST,
      "other": IMAGES.OTHER,
    }
  },
  [QUIZ.SPOTEN_LOCATION_HISTORY]: {
    type: 'single',
    title: 'Would you like to see their location history?',
    options: {
      "absolutely": "Absolutely",
      "maybe": "Maybe",
      "not_really": "Not really",
    },
  },
  [QUIZ.SPOTEN_FOLLOWING_OLD]: {
    type: 'multi',
    title: 'Which aspects of their life do you most want to track?',
    options: {
      "schedule": "Schedule",
      "driving": "Driving Patterns",
      "visited": "Most Visited Places",
      "battery": "Phone’s Battery Status",
      "none": "None of the above",
    },
    images: {
      "schedule": IMAGES.SCHEDULE_OLD,
      "driving": IMAGES.DRIVING_OLD,
      "visited": IMAGES.VISITED_OLD,
      "battery": IMAGES.BATTERY_OLD,
      "none": IMAGES.NONE_SMALL_OLD,
    },
  },
  [QUIZ.SPOTEN_FOLLOWING]: {
    type: 'multi',
    title: 'Which aspects of their life do you most want to track?',
    options: {
      "schedule": "Schedule",
      "driving": "Driving Patterns",
      "visited": "Most Visited Places",
      "battery": "Phone’s Battery Status",
      "none": "None of the above",
    },
    images: {
      "schedule": IMAGES.SCHEDULE,
      "driving": IMAGES.DRIVING,
      "visited": IMAGES.VISITED,
      "battery": IMAGES.BATTERY,
      "none": IMAGES.NONE_SMALL,
    },
  }
};
