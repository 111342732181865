import { initializeApp as initFirebase } from 'firebase/app';

import { parseUrlParams } from '@web-solutions/core/utils/url-sync';

import { render } from './render';

parseUrlParams();

let paths = window.location.pathname.split('/');

let promiseApp;

if (paths[1] === 'terminate') {
  promiseApp = import('./screens/terminate').then(r => r.default);
} else if (paths[1] === 'quiz-book') {
  promiseApp = import('./screens/quiz/book').then(r => r.default);
} else if (paths[1] === 'summary-book') {
  promiseApp = import('./screens/subscription/book').then(r => r.default);
} else {
  promiseApp = import('./screens/quiz').then(r => r.default);
}

initFirebase({
  apiKey: 'AIzaSyBACGqyDUkWKO_ohxGGbXVjLL9sMr4vS38',
  authDomain: 'family-spots-1362a.firebaseapp.com',
  projectId: 'family-spots-1362a',
  storageBucket: 'family-spots-1362a.appspot.com',
  messagingSenderId: '996257639491',
  appId: '1:996257639491:web:368ae4614297a8f9d2b8f6',
  measurementId: 'G-1J14HRYE0N'
});

promiseApp.then(render);
