export enum SPOTEN_FIND_WHO_ANSWERS {
  loved = 'loved',
  friend = 'friend',
  member = 'member',
};

export const SUBSCRIPTION_NAMES = {
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  GPS_TRACKER_UPSALE: 'GPS_TRACKER_UPSALE',
} as const;

type AfterPurchase = Exclude<keyof typeof SUBSCRIPTION_NAMES, "MAIN" | "SUCCESS">
export type AfterPurchaseRoutes = AfterPurchase[];