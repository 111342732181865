import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks';

import { STEPS } from 'src/screens/quiz/questions';
import { QUIZ } from 'src/constants/routes';

const ROUTES = Object.keys(STEPS).concat(Object.keys(QUIZ));

//@ts-ignore
const getFlow = (state) => state.remoteConfig.flow;
const getMember = (state: any) => state.summary.member

export const selectFlowBlocks = createSelector([getFlow, getMember], (flow, member) => {
  let r = normalizeBlocks(flow);
  r.forEach((arr, i) => {
    r[i] = arr.filter((item) => {
      if (members.includes(item) && member === 'loved') {
        return false
      }

      if (loved.includes(item) && (member === 'member' || member === 'friend')) {
        return false
      }

      return true
    })
  })
  r = r.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);
  return r;
});

const loved: string[] = [QUIZ.SPOTEN_LOVED_BEHAVIOR_CHANGE, QUIZ.SPOTEN_LOVED_CHEATING, QUIZ.SPOTEN_LOVED_WORRIES_MOST];
const members: string[] = [QUIZ.SPOTEN_MEMBER_BEHAVIOR_CHANGE, QUIZ.SPOTEN_MEMBER_WORRIES_MOST, QUIZ.SPOTEN_MEMBER_KEEP_CONTACT];

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow;
});
