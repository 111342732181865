import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { Text } from '@web-solutions/core/ui-elements'

import classes from './style.module.scss'

interface DescriptionOptionsProps extends ComponentProps<'div'> {
  title: string,
  options: string[]
}

export const DescriptionOptions: FC<DescriptionOptionsProps> = ({ title, options, className, ...props }) =>
  <div className={classNames(classes.optionsWrapper, className)} {...props}>
    <Text className={classes.title}>
      {title}
    </Text>
    <ul className={classes.list}>
      {options.map((option) =>
        <li className={classes.option}>
          <Text className={classes.optionText}>
            {option}
          </Text>
        </li>
      )}
    </ul>
  </div>         