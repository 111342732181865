import { createAsyncThunk } from '@reduxjs/toolkit';

import { profileSlice } from '@web-solutions/base-app/store/profile';

import { auth } from 'src/api/auth'
import { updateProfile } from 'src/api/profile'

export { processEmail } from '@web-solutions/base-app/store/profile/actions';

export const authAndUpdateProfile = createAsyncThunk(
  `${profileSlice.name}/authAndUpdateProfile`,
  async (_, { getState }) => {
    const { summary } = getState() as any;

    try {
      await auth()
      await updateProfile(summary);
    }
    catch (error: any) {
      if (error?.status !== 422) {
        throw error;
      }
      console.log('[ERROR AUTH]', error);
    }
  }
)
