import { createAsyncThunk } from '@reduxjs/toolkit';

import Analytics from '@web-solutions/module-analytics';
import { updateUserEmail } from '@web-solutions/base-app/api/auth';

//@ts-ignore
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';
//@ts-ignore
import Billing from '@web-solutions/module-billing';
import { auth as authRequest } from '@web-solutions/base-app/api/auth';

import { reInit as reInitRemoteConfig } from '@web-solutions/core/store/remote-config/actions';

import { setApiAuth } from '@web-solutions/core/utils/network';

import { profileSlice } from '.';


export const { setAge, setEmail, setAuthorized, setGender, setRelationshipStatus } = profileSlice.actions;

export const processRelationshipStatus = createAsyncThunk(
  `${profileSlice.name}/processRelationshipStatus`,
  async (status: string, { dispatch }) => {
    Analytics.setUserProperty('relationship_status', status);
    dispatch(setRelationshipStatus(status));
  }
);

export const processAge = createAsyncThunk(
  `${profileSlice.name}/processAge`,
  async (age: string, { dispatch }) => {
    Analytics.setUserProperty('age', age);
    dispatch(setAge(age));
  }
);



export const auth = createAsyncThunk(
  `${profileSlice.name}/auth`,
  async (_, { dispatch }) => {

    return authRequest().then((res: any) => {
      setApiAuth(`${res.token_type} ${res.access_token}`);
      Billing.setApiAuth(`${res.token_type} ${res.access_token}`);
      dispatch(setAuthorized(res));
    });
  }
);

export const processGender = createAsyncThunk(
  `${profileSlice.name}/processGender`,
  async (gender: string, { dispatch }) => {
    Analytics.setUserProperty('gender', gender);
    await dispatch(reInitRemoteConfig());
    dispatch(setGender(gender));
  }
);


export const processEmail = createAsyncThunk(
  `${profileSlice.name}/processEmail`,
  async (email: string, { dispatch }) => {
    Analytics.setUserProperty('email', email);
    Analytics.trackEvent('user', 'info', { email, });

    Analytics.trackEvent('firebase_auth', 'update_email_submit', { email });
    updateUserEmail(email)
      .then(() => {
        Analytics.trackEvent('firebase_auth', 'update_email_success', { email });
      })
      .catch((error) => {
        Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email });
      });

    dispatch(setEmail(email));
    stringifyUrlParams({ email })
  }
);