import { getAuth, signInWithCustomToken, updateEmail, type UserCredential } from 'firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

// @ts-ignore
import { request as requestBillingApi } from '@web-solutions/module-billing/api/network';

const billingApi = requestBillingApi(process.env.REACT_APP_API_BILLING_URL);

const authDeviceId = (idfm: string) =>
  billingApi.post('/auth/device-id', { device_id: idfm, });

const authFirebase = (idfm: string) =>
  billingApi.post<any, { custom_token: string }>('/auth/firebase/create-custom-token', { uid: idfm, })
    .then(({ custom_token }: { custom_token: string }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred: UserCredential) => userCred.user.getIdToken())
    .then((token: string) => billingApi.post('/auth/firebase', { token, }));

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm =>
      Promise.any([
        authDeviceId(idfm),
        authFirebase(idfm),
      ]));

export const updateUserEmail = (email: string) =>
  DeviceProps.getIDFM()
    .then(authFirebase)
    .then(() => updateEmail(getAuth().currentUser!, email));
