/* eslint-disable no-use-before-define */
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _without from 'lodash/without';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';

import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Currency } from 'core/../react-billing/constants';

import DeviceProps from '@magnus/react-native-device-props';
import Mutator from '@magnus/react-native-mutator';

import { t } from '@web-solutions/module-localization';
//@ts-ignore
import Billing from '@web-solutions/module-billing';
//@ts-ignore
import { COUNTRIES } from '@web-solutions/module-billing/utils';
import { mappingPricesToProducts, PaymentSystem, ProductInfo, type SolidgatePayPalOrder } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { get3DSRedirectURLs } from '@web-solutions/core/utils/billing'

import type { SubscribeOnOneClickParams, SubscribeOnOneClickResponse } from '@web-solutions/core/store/billing/types';

import { stringifyUrlParams } from 'core/utils/url-sync';
import { PaddleConfig, Purchase, Subscription, SubscriptionProps } from 'core/interfaces/billing';
import { ProductConfig, RemoteConfig } from 'core/constants/remote-config';
import { ModePayPal, PURCHASE_METHODS } from 'core/constants/billing';

//@ts-ignore
import { processEmail } from 'src/store/profile/actions';

import { remoteConfigSelector } from '../remote-config/selectors';

import { getProductsIds, prepareProductsIds } from './utils';
import { ProductDetails, selectPaymentProject, selectPaymentSystem, selectAllProducts, selectPurchased, selectOneTimePurchases, } from './selectors';

import * as TYPES from './types';

import { PaymentMethod, type OneTimePurchase } from './index';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc)

const setPaymentSystem = createAction(TYPES.SET_PAYMENT_SYSTEM, (paymentSystem: PaymentSystem) => ({ paymentSystem }));
export const setPurchase = createAction(TYPES.SET_PURCHASE, (purchase: Purchase) => ({ purchase }));
export const resetPurchase = createAction(TYPES.RESET);
export const setPromocodeActivated = createAction(TYPES.SET_PROMOCODE_ACTIVATED);
export const setIsSubmitByCard = createAction<boolean>(TYPES.SET_IS_SUBMIT_BY_CARD);
const setLoading = createAction(TYPES.SET_LOADING);
export const setPending = createAction(TYPES.SET_PENDING);
const setOrderDetails = createAction(TYPES.SET_ORDER_DETAILS);
const setOrdersPayPal = createAction<Record<string, SolidgatePayPalOrder>>(TYPES.SET_ORDERS_PAYPAL);
const setOrderPending = createAction(TYPES.SET_ORDER_PENDING);
export const setPaddleConfig = createAction<PaddleConfig>(TYPES.SET_PADDLE_CONFIG);
const setProducts = createAction<ProductInfo[]>(TYPES.SET_PRODUCTS);
export const setTrialPrice = createAction(TYPES.SET_TRIAL_PRICE);
const setDiscountEndDate = createAction(TYPES.SET_DISCOUNT_END_DATE);
const setNoFundsOfferEndDate = createAction(TYPES.SET_NO_FUNDS_END_DATE);
const setSpecialOfferEndDate = createAction(TYPES.SET_SPECIAL_OFFER_END_DATE);
const setSpecialOfferProducts = createAction(TYPES.SET_SPECIAL_OFFER_PRODUCTS);
const setPromocodeOfferProducts = createAction(TYPES.SET_PROMOCODE_OFFER_PRODUCTS);
export const setPostCode = createAction<string>(TYPES.SET_POSTCODE);
export const setInjectedPaymentMethod = createAction<string>(TYPES.SET_INJECTED_PAYMENT_METHOD);
export const setOneTimePurchases = createAction<OneTimePurchase[]>(TYPES.SET_ONE_TIME_PURCHASES);
export const setPaymentMethod = createAction<PaymentMethod | null>(TYPES.SET_PAYMENT_METHOD);
export const setTrialsLeftCounter = createAction(TYPES.SET_TRIALS_LEFT_COUNTER);

let initPromise: Promise<any> | null;

export const init = (): ThunkAction<Promise<any>, any, unknown, any> =>
  async (dispatch, getState) => {
    if (!initPromise) {
      const state = getState();
      const {
        billing: { paddleConfig, },
      } = state;

      const paymentSystem = selectPaymentSystem(state);
      const paymentProject = selectPaymentProject(state);

      dispatch(setLoading(true));

      const p = [
        Billing.init(paymentSystem, paymentProject),
        dispatch(initDiscount()),
        dispatch(initProducts()),
      ];

      const qs = queryString.parse(window.location.search);

      if (qs.success === PaymentSystem.PADDLE) {
        let checkout_id = qs.checkout_id || paddleConfig?.checkoutId;
        let product_id = qs.product_id || paddleConfig?.productId;
        let payment_method = qs.payment_method || paddleConfig?.paymentMethod;
        if (checkout_id) {
          createBrowserHistory()
            .replace(window.location.pathname + '?' + queryString.stringify(_omit(qs, ['success', 'checkout_id', 'product_id', 'payment_method'])));
          p.push(
            dispatch(createCustomer({ paymentSystem: PaymentSystem.PADDLE }))
              .then(() => dispatch(subscribe({
                checkout_id: checkout_id,
                price_id: product_id,
                method: payment_method,
                paymentSystem: PaymentSystem.PADDLE,
              })))
          );
        }
      }

      try {
        const oneTimePurchases = (await Billing.getOneTimePurchases());
        dispatch(setOneTimePurchases(oneTimePurchases));
      } catch (e) {
        console.log('[ERROR GET ONE TIME PURCHASES]:', e);
      }


      if (paymentSystem === PaymentSystem.PADDLE) {
        Promise.all([
          Mutator.getCountryCode(),
          DeviceProps.getIDFM(),
        ])
          .catch(() => ([]))
          .then(([country, idfm]) => {
            dispatch(setPaddleConfig({
              country,
              passthrough: idfm
                ? JSON.stringify({
                  idfm,
                  payment_system_project: paymentProject,
                })
                : undefined,
            }));
          });
      }

      initPromise = Promise.all(p)
        .catch((error) => {
          console.log('[ERROR INIT BILLING]:', error);
          return false;
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }

    return initPromise;
  };

const initProducts = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const paymentSystem = selectPaymentSystem(state);

    let { products, } = state.remoteConfig as RemoteConfig;

    products = prepareProductsIds(products, paymentSystem!);

    let preparedProducts = await dispatch(fetchProducts(products));

    dispatch(setProducts(preparedProducts));
    await dispatch(initSpecialOfferProducts());
    await dispatch(initPromocodeOfferProducts());
    dispatch(initOrders());
  };

const initPromocodeOfferProducts = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const paymentSystem = selectPaymentSystem(state);

    const { promocodeOffer } = state.remoteConfig as RemoteConfig;

    if (promocodeOffer?.enabled && promocodeOffer?.products?.length) {
      const products = prepareProductsIds(promocodeOffer.products, paymentSystem!);
      dispatch(setPromocodeActivated(promocodeOffer?.switchDefaultValue))
      let preparedProducts = await dispatch(fetchProducts(products));

      dispatch(setPromocodeOfferProducts(preparedProducts));
    }
  };

const initSpecialOfferProducts = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const paymentSystem = selectPaymentSystem(state);

    const { specialOffer, } = state.remoteConfig as RemoteConfig;

    if (specialOffer?.enabled && specialOffer?.products?.length) {
      const products = prepareProductsIds(specialOffer.products, paymentSystem!);

      let preparedProducts = await dispatch(fetchProducts(products));

      dispatch(setSpecialOfferProducts(preparedProducts));
    }
  };

export const initSpecialOfferEndDate = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const { specialOffer } = state.remoteConfig as RemoteConfig;
    dispatch(setSpecialOfferEndDate(dayjs().add(specialOffer.time, 'second')));
  };

export const initNoFundsOfferEndDate = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const { specialOffer } = state.remoteConfig as RemoteConfig;
    dispatch(setNoFundsOfferEndDate(dayjs().add(specialOffer.time, 'second')));
  };

export const getPaymentMethod = (): ThunkAction<Promise<PaymentMethod | null>, any, unknown, any> =>
  async (dispatch) => {
    const res = (await Billing.getPaymentMethod());
    const paymentMethod = PURCHASE_METHODS[res.payment_method];
    dispatch(setPaymentMethod(paymentMethod));
    return paymentMethod;
  }

export const getOneTimePurchases = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch) => {
    const oneTimePurchases = (await Billing.getOneTimePurchases());
    dispatch(setOneTimePurchases(oneTimePurchases));
  }

export const fetchProducts = (products: ProductConfig[]): ThunkAction<Promise<ProductInfo[]>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      productsDetails,
    } = state.remoteConfig as RemoteConfig;

    const paymentSystem = selectPaymentSystem(state);

    const [country, prices] = await Promise.all([
      Mutator.getCountryCode(),
      Billing.getPrices(getProductsIds(products), { paymentSystem }),
    ]);

    return mappingPricesToProducts(products, [...prices, ..._filter(productsDetails, { period: 'ONETIME' })], COUNTRIES[country]);
  };

export const initOrders = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const products = selectAllProducts(state);
    const paymentSystem = selectPaymentSystem(state);
    const { modePayPal } = remoteConfigSelector(state);

    const res = await dispatch(createCustomer({ paymentSystem }));

    if (!!res?.email) {
      dispatch(processEmail(res.email));
    }

    const product = products.find((product) => product.default) || products[0];
    if (product) {
      if (paymentSystem === PaymentSystem.SOLIDGATE) {
        dispatch(
          createOrders({
            product,
            products: products,
            currency: product.currency,
            paymentSystem: PaymentSystem.SOLIDGATE,
          }),
        );

        if (modePayPal === ModePayPal.SOLIDGATE) {
          dispatch(
            createPayPalOrders({
              paymentSystem,
              products: [product],
              currency: product.currency,
            })
          );
          dispatch(
            createPayPalOrders({
              paymentSystem,
              products: _without(products, product),
              currency: product.currency,
            })
          );
        }

      } else if (paymentSystem === PaymentSystem.PADDLE) {
        const product = products.find((product) => product.default) || products[0];
        dispatch(
          createOrders({
            product: { ...product, id: product.solidgateId! },
            products: products.map(p => ({ ...p, id: p.solidgateId })).filter(p => !!p.id) as ProductDetails[],
            currency: product.currency,
            paymentSystem: PaymentSystem.SOLIDGATE,
          }),
        );
      }
    }
  }

const initDiscount = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      remoteConfig: { discountTime: time },
      billing: { discountEndDate },
    } = getState();

    if (time) {
      if (!discountEndDate) {
        dispatch(setDiscountEndDate(dayjs().add(time, 'second')));
      }
    } else {
      if (discountEndDate) {
        dispatch(setDiscountEndDate(null));
      }
    }
  };

export const createCustomer =
  ({ email, paymentSystem, paymentProject }: { email?: string, paymentSystem?: PaymentSystem, paymentProject?: string }): ThunkAction<Promise<{ email: string }>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const { profile } = getState();
        // Get email from form, otherwise get it from profile. Customer created from paypal won't have email
        email = email || profile.email;
        if (email) {
          Analytics.setUserProperty('email', email);
        }
        dispatch(setPending(true));
        return await Billing.createCustomer({ email, paymentSystem, paymentProject });
      } catch (error) {
        console.log('[ERROR ASSIGN CUSTOMER]', error);
        throw error;
      } finally {
        dispatch(setPending(false));
      }
    };

const createOrders =
  ({ product, products, currency, paymentSystem, idfv }:
    { product: ProductDetails, products: ProductDetails[], currency: Currency, paymentSystem?: PaymentSystem, idfv?: string }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      const idfm = await DeviceProps.getIDFM();

      const { isSendingTerminateLinks, isSendingTrialEnd} = remoteConfigSelector(getState());

      try {
        dispatch(setOrderDetails(null));
        dispatch(setOrderPending(true));
        const orderDetails = await Billing.createOrder({
          ...(product.isOneTimePurchase
            ? {
              one_time_product: {
                product: product.id,
                amount: product.amount,
                currency: product.currency,
              },
              one_time_products: products.map(p => ({
                product: p.id,
                amount: p.amount,
                currency: p.currency,
              })),
            }
            : {
              productId: product.id,
              products: _uniq(getProductsIds(products)),
              currency,
            }
          ),
          idfv,
          orderDescription: idfm,
          paymentSystem,
          is_sending_terminate_links: isSendingTerminateLinks,
          is_sending_trial_end: isSendingTrialEnd,
        });
        orderDetails.productId = product.id;
        dispatch(setOrderDetails(orderDetails));
      } catch (error) {
        console.log('[ERROR CREATE ORDER]', error);
        throw error;
      } finally {
        dispatch(setOrderPending(false));
      }
    };

export const createPayPalOrders =
  ({ products, currency, paymentSystem, trigger, idfv, metadata }:
    { products: ProductDetails[], currency: Currency, paymentSystem?: PaymentSystem, trigger?: string, idfv?: string, metadata?: any }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      const idfm = await DeviceProps.getIDFM();

      const { isSendingTerminateLinks, isSendingTrialEnd } = remoteConfigSelector(getState());

      dispatch(setOrdersPayPal({}));

      Billing.createPayPalOrder({
        ...(products[0]?.isOneTimePurchase
          ? {
            one_time_products: products.map(p => ({
              product: p.id,
              amount: p.amount,
              currency: p.currency,
            })),
          }
          : {
            products: products.map(p => p.id),
          }),
        idfv,
        currency,
        metadata,
        paymentSystem,
        trigger,
        orderDescription: idfm,
        is_sending_terminate_links: isSendingTerminateLinks,
        is_sending_trial_end: isSendingTrialEnd,
      })
        .then((orders: Record<string, SolidgatePayPalOrder>) => {
          dispatch(setOrdersPayPal(orders));
        });
    };

interface CreateCheckout {
  productId: string,
  checkoutId: string,
  paymentMethod: string,
  paymentSystem: PaymentSystem
}

export const createCheckout =
  ({ productId, checkoutId, paymentMethod, paymentSystem }: CreateCheckout): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const p = queryString.parse(window.location.search);
        const successUrl = queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            ...p,
            success: PaymentSystem.PADDLE,
          },
        });

        await Billing.createCheckout({
          productId,
          checkoutId,
          paymentMethod,
          successUrl,
          paymentSystem,
        });
      } catch (error) {
        console.log('[ERROR CREATE CHECKOUT]', error);
        throw error;
      }
    };

export const handleSuccessPurchase = (subscription: Subscription): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const { transaction_id, price_id, plan_name, amount, currency, method, } = subscription;

    const actualPlanId = price_id ?? plan_name

    const state = getState();

    const products = selectAllProducts(state);
    const purchased = selectPurchased(state);
    const oneTimePurchases = selectOneTimePurchases(state);

    const {
      profile: { email },
    } = state;

    const paymentSystem = selectPaymentSystem(state);

    const p = _find(products, { id: actualPlanId }) || _find(products, { paypalPlanId: actualPlanId });

    if (subscription.email || email || subscription.first_name || subscription.last_name) {
      Analytics.trackEvent('user', 'info', {
        email: subscription.email || email || undefined,
        first_name: subscription.first_name || undefined,
        last_name: subscription.last_name || undefined,
      });
    }
    if (!purchased && !p?.isOneTimePurchase) {
      Analytics.trackPurchaseEvent({
        transactionId: transaction_id,
        productId: actualPlanId,
        revenue: +(p?.trialPriceAmount || p?.amount || amount),
        currency,
        method,
        email,
        paymentSystem: subscription.paymentSystem || paymentSystem,
      });
    }

    if (!p?.isOneTimePurchase || !purchased) {
      dispatch(setPurchase({
        ...subscription,
        isTrial: !!p?.isTrial,
        product: p!,
        paymentSystem: subscription.paymentSystem || paymentSystem,
      }));
    }

    if (p?.isOneTimePurchase) {
      dispatch(setOneTimePurchases([
        ...oneTimePurchases,
        {
          product_code: p.id,
          payment_service: subscription.paymentSystem || paymentSystem,
          currency: p?.currency,
        }
      ]));
    }

    stringifyUrlParams({ purchased: null });

    return subscription;
  };

export const handleErrorPurchase = (error: any, { noToast }: { noToast: boolean | undefined } = { noToast: false }): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  console.warn(error);

  if (!error?.data?.three_d_secure_action_token_id) {
    let customMessage;
    if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      customMessage = t(`core.solidgate_payment_errors`, { returnObjects: true })[error?.code];
    }

    if (!noToast) {
      toast(customMessage || error?.message || 'Something went wrong', { type: 'error', autoClose: customMessage ? 7500 : 5000 });
    }

    Analytics.trackEvent('ecommerce', 'error', {
      message: error?.message,
      code: error?.code,
      paymentSystem: error?.paymentSystem,
      method: error?.method,
    });
  }
};

export const subscribe = (formData: SubscriptionProps): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      profile: { email },
      remoteConfig: { isSendingTerminateLinks, isSendingTrialEnd },
    } = getState();

    try {
      dispatch(setPending(true));

      // If we have no email in form, then get it from profile.
      if (!formData.email && email) {
        formData.email = email;
      }

      if (formData.email) {
        Analytics.setUserProperty('email', formData.email);
      }

      const subscriptionRes = await Billing.subscribe({
        ...formData,
        is_sending_terminate_links: isSendingTerminateLinks,
        is_sending_trial_end: isSendingTrialEnd,
      });

      const subscriptionDetails = {
        ...formData,
        ...subscriptionRes,
      };

      return dispatch(handleSuccessPurchase(subscriptionDetails));
    } catch (error: any) {
      const err = error || {};
      err.paymentSystem = formData.paymentSystem || err.paymentSystem;
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const applePayOrder = (formData: any): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      profile: { email },
      remoteConfig: { isSendingTerminateLinks, isSendingTrialEnd },
    } = getState();

    try {
      dispatch(setPending(true));

      // If we have no email in form, then get it from profile.
      if (!formData.email && email) {
        formData.email = email;
      }

      if (formData.email) {
        Analytics.setUserProperty('email', formData.email);
      }

      const subscriptionRes = await Billing.applePayOrder({
        ...formData,
        is_sending_terminate_links: isSendingTerminateLinks,
        is_sending_trial_end: isSendingTrialEnd,
      });

      const subscriptionDetails = {
        ...formData,
        amount: subscriptionRes?.order?.amount / 100 || undefined,
        currency: subscriptionRes?.order?.currency,
        method: 'applebtn',
        order: subscriptionRes?.order,
        transaction_id: subscriptionRes?.order?.subscription_id,
      };

      return dispatch(handleSuccessPurchase(subscriptionDetails));
    } catch (error: any) {
      const err = error || {};
      err.paymentSystem = formData.paymentSystem || err.paymentSystem;
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const checkActiveSubscription = (args: { isAsb?: boolean }): ThunkAction<Promise<[] | undefined>, any, unknown, any> =>
  async (dispatch, getState) => {
    try {
      const { billing: { purchase } } = getState();

      if (purchase) {
        const { data } = await Billing.getSubscriptions(args?.isAsb);

        if (data) {
          const isActiveSubscription = data.some((subscription: any) => subscription?.active);

          if (!isActiveSubscription) {
            window.localStorage.clear();
            const p = queryString.parse(window.location.search);
            delete p.completed;
            delete p.purchased;
            createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
          }
          return data;
        }
      }
    } catch (error) {
      console.log('[ERROR GET SUBSCRIPTIONS]', error);
    }
  };

export const changePlan = (productId: string, subscriptionId: string, isNewSubscription: boolean): ThunkAction<Promise<any>, any, unknown, any> =>
  async (dispatch, getState) => {
    dispatch(setPending(true));
    try {
      const { failUrl, successUrl } = get3DSRedirectURLs()

      const r = ((await Billing.changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl })) || {});

      console.log(r);

      return r;
    } finally {
      dispatch(setPending(false));
    }
  }

export const switchToReservePaymentSystem = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentSystem = selectPaymentSystem(state);
    const { paymentSystemReserve } = state.remoteConfig;

    if (paymentSystemReserve && paymentSystem !== paymentSystemReserve) {
      dispatch(setPaymentSystem(paymentSystemReserve));
      initPromise = null;
      dispatch(init());
    }
  }

interface OneClickPaymentArgs {
  amount: number,
  currency: Currency,
  productCode: string,
  withTransactionDetailsEmail?: boolean,
  force3ds?: boolean,
  trigger?: string,
  metadata?: { chatId?: string }
}

export const subscribeOnOneClick = (params: SubscribeOnOneClickParams): ThunkAction<Promise<SubscribeOnOneClickResponse>, any, unknown, any> =>
  async () => {
    const { failUrl, successUrl } = get3DSRedirectURLs()

    return await Billing.subscribeOnOneClick({
      ...params,
      success_url: successUrl,
      fail_url: failUrl,
    })
  }

export const createOneClickPayment = ({
  amount,
  currency,
  productCode,
  trigger = '',
  force3ds,
  metadata,
  withTransactionDetailsEmail,
}: OneClickPaymentArgs): ThunkAction<Promise<void>, any, unknown, any> =>
  async () => {
    const { failUrl, successUrl } = get3DSRedirectURLs()

    const description = await DeviceProps.getIDFM();
    return await Billing.createOneClickPayment({
      withTransactionDetailsEmail,
      force3ds,
      amount,
      currency,
      productCode,
      successUrl,
      failUrl,
      trigger,
      metadata,
      description
    });
  }

export const giveProduct = (productId: string): ThunkAction<Promise<void>, any, unknown, any> =>
  async () => {
    return Billing.giveProduct(productId);
  }
