import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  authorized: boolean;
  apiAuth: string | null;
  email: string;
  gender: string | null;
  age: string | null;
  relationship_status: string | null;

  is_unsubscriber: boolean | null | number
}


const initialState: ProfileState = {
  authorized: false,
  apiAuth: null,
  email: '',
  gender: null,
  age: null,
  relationship_status: null,

  is_unsubscriber: null
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setAge: (state, action: PayloadAction<string>) => {
      state.age = action.payload;
    },
    setGender: (state, action: PayloadAction<string>) => {
      state.gender = action.payload;
    },
    setRelationshipStatus: (state, action: PayloadAction<string>) => {
      state.relationship_status = action.payload;
    },
    setAuthorized: (state, action: PayloadAction<string>) => {
      state.authorized = true;
      state.apiAuth = action.payload;
    }
  }
})

export const blacklist = ['authorized'];

export { slice as profileSlice };



